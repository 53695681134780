import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { SessionMode } from '../session.component';

@Component({
  selector: 'app-email-verified',
  templateUrl: './email-verified.component.html',
  styleUrls: ['./email-verified.component.scss'],
})
export class EmailVerifiedComponent implements OnInit {
  @Output() switchMode: EventEmitter<SessionMode> =
    new EventEmitter<SessionMode>();
  @Input() oobCode: string = null;
  mode: 'loading' | 'success' | 'failed' = 'success';

  constructor(
    private activatedRoute: ActivatedRoute,
    private firebaseAuth: AuthenticationService
  ) {}

  async ngOnInit() {
    try {
      if (!this.oobCode) {
        this.mode = 'failed';
        return;
      }

      await this.firebaseAuth.validationEmail(this.oobCode);

      this.mode = 'success';
    } catch (e) {
      console.error(e);
      this.mode = 'failed';
    }
  }
}
