import { InternationalizedService } from '../services/internationalized.service';
import { FirebaseDeserializable } from './FirebaseDeserializable';
import { Model } from './generic-model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export class UniversitiesDTO extends Model {
  //What Firebase should return from the universities document
  public name?: { en: string; es: string; fr: string };
  public picture?: string;
  public projectIds: string[];
  public website: string;
  public faculty?: string;
  public city?: { en: string; es: string; fr: string };
  public country?: { en: string; es: string; fr: string };
}

export class Universities
  extends UniversitiesDTO
  implements FirebaseDeserializable<UniversitiesDTO>
{
  constructor(data: any = {}) {
    super();
    for (const name in data) {
      if (data.hasOwnProperty(name)) {
        this[name] = data[name];
      }
    }
  }

  deserialize(input: UniversitiesDTO): this {
    Object.assign(this, input);
    return this;
  }

  get displayName() {
    const lang = InternationalizedService.getLocale();
    return this.name[lang];
  }

  get displayCity() {
    const lang = InternationalizedService.getLocale();
    return this.city[lang];
  }

  get displayCountry() {
    const lang = InternationalizedService.getLocale();
    return this.country[lang];
  }
}
