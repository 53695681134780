import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-cookies-model',
  standalone: true,
  imports: [CommonModule, MatButtonModule],
  templateUrl: './cookies-model.component.html',
  styleUrls: ['./cookies-model.component.scss']
})
export class CookiesModelComponent {
  @Input() close: boolean = false; // Input to control visibility from parent
  @Output() closeChange = new EventEmitter<boolean>(); // Emit close state change
  @Output() onAcceptCookies = new EventEmitter<void>(); // Emit accept event

  closeDialog(): void {
    this.close = true;
    this.closeChange.emit(this.close); // Notify parent about close action
  }

  acceptCookies(): void {
    this.onAcceptCookies.emit(); // Notify parent about acceptance
    this.closeDialog(); // Automatically close the dialog after acceptance
  }

}
