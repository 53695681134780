<div class="project-open-data">
  <h1 class="project-open-data__name">{{ definition.openData.title }}</h1>
  <p class="project-open-data__description">
    {{ definition.openData.Description }}
  </p>
  <div class="project-open-data__downloadable-formats">
    <ng-container *ngFor="let format of definition.openData.Formats">
      <a (click)="generateDownloadLink(format)">
        <ng-container *ngIf="formatMessage[format]; else formatText">
          <mat-spinner
            diameter="15"
            *ngIf="formatMessage[format] === 'loading'"
          ></mat-spinner>
          <ng-container *ngIf="formatMessage[format] !== 'loading'">{{
            formatMessage[format]
          }}</ng-container>
        </ng-container>
        <ng-template #formatText>
          {{ format }}
        </ng-template>
      </a>
    </ng-container>
  </div>
  <span class="project-open-data__confirm-message" *ngIf="confirm_message">
    {{ confirm_message | translate }}
  </span>
</div>
