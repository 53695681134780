<div class="project" *ngIf="project">
  <div class="project__loading-screen" *ngIf="isLoading; else loadedContent">
    <mat-spinner></mat-spinner>
  </div>
  <ng-template #loadedContent>
    <div class="project__hero">
      <div
        class="project__hero__background project__hero__background--{{
          project.type
        }}"
      ></div>
      <img class="project__hero__preview" [src]="project.icon" alt="" />
      <div class="project__hero__title">
        {{ project.displayTitle }}
      </div>
      <div class="project__hero__contacts">
        <div class="project__hero__contacts__item" *ngIf="project.website">
          <img
            class="project__hero__contacts__item__icon"
            src="../../../assets/images/icon-web.png"
            alt="website"
          />
          <a
            class="project__hero__contacts__item__value"
            href="{{ project.website }}"
            target="_blank"
          >
            {{ project.website }}
          </a>
        </div>
      </div>
      <div class="project__hero__description" *ngIf="project.description">
        {{ project.displayDescription }}
      </div>
    </div>

    <mat-tab-group class="project__tab">
      <mat-tab label="{{ 'project.label.title' | translate }}">
        <app-project-section
          class="project__section"
          *ngIf="project.displayMission"
          [definition]="project.displayMission"
        ></app-project-section>

        <div class="project__section">
          <div class="project__section__title">
            {{ "project.title" | translate }}
          </div>
          <div class="project__section__separator">
            <img
              class="project-section__title__decoration"
              src="../../../assets/images/shape_line_section_project.svg"
            />
          </div>
          <div
            class="project__section__content"
            [innerText]="project.displayHistoric"
          ></div>
          <div class="project__section__gallery">
            <img
              class="project__section__gallery__previous"
              src="assets/images/next.svg"
              alt="Previous"
              (click)="setGalleryIndex(galleryIndex - 1)"
            />

            <div class="project__section__gallery__content">
              <div
                class="project__section__gallery__content__screenshot"
                [ngClass]="{
                  'project__section__gallery__content__screenshot--visible':
                    project.screenshots[galleryIndex] === screenshot
                }"
                *ngFor="let screenshot of project.screenshots"
              >
                <img
                  [src]="screenshot.picture"
                  class="
                    project__section__gallery__content__screenshot__picture
                  "
                  alt=""
                  (click)="selectScreenshot(screenshot)"
                />
                <div
                  class="project__section__gallery__content__screenshot__text"
                >
                  {{ screenshot.description[translate.currentLang] }}
                </div>
              </div>
            </div>

            <img
              class="project__section__gallery__next"
              src="assets/images/next.svg"
              alt="Next"
              (click)="setGalleryIndex(galleryIndex + 1)"
            />
          </div>
        </div>

        <div class="project__section" *ngIf="hasStudentTeamMember()">
          <div class="project__section__title">
            {{ "project.student-team-title" | translate }}
          </div>
          <div class="project__section__separator">
            <img
              class="project-section__title__decoration"
              src="../../../assets/images/shape_line_section_project.svg"
            />
          </div>
          <div class="project__section__content">
            <p *ngIf="!projectContributor; else studentPartnerContent">
              {{ "project.no-student-team-content" | translate }}
            </p>
          </div>
          <ng-template #studentPartnerContent>
            <div class="project__section__content--contributor">
              <ng-container *ngFor="let contributor of projectContributor">
                <div
                  class="project__section__contributor"
                  (click)="selectContributor(contributor)"
                  *ngIf="!contributor.isFacultyMember"
                >
                  <div
                    class="project__section__contributor__image"
                    [ngStyle]="{
                      backgroundImage: 'url(\'' + contributor.picture + '\')'
                    }"
                  >
                    <img
                      class="project__section__contributor__image__quotes"
                      src="assets/images/quotes.png"
                      alt=""
                    />
                  </div>
                  <div class="project__section__contributor__body">
                    <div class="project__section__contributor__body__name">
                      {{ contributor.firstName }} {{ contributor.lastName }}
                    </div>
                    <div class="project__section__contributor__body__quote">
                      {{ contributor.displayQuote }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </div>

        <div class="project__section" *ngIf="hasFacultyTeamMember()">
          <div class="project__section__title">
            {{ "project.faculty-team-title" | translate }}
          </div>
          <div class="project__section__separator">
            <img
              class="project-section__title__decoration"
              src="../../../assets/images/shape_line_section_project.svg"
            />
          </div>
          <div class="project__section__content">
            <p *ngIf="!projectContributor; else researchPartnerContent">
              {{ "project.no-faculty-team-content" | translate }}
            </p>
          </div>
          <ng-template #researchPartnerContent>
            <div class="project__section__content--contributor">
              <ng-container *ngFor="let contributor of projectContributor">
                <div
                  class="project__section__contributor"
                  (click)="selectContributor(contributor)"
                  *ngIf="contributor.isFacultyMember"
                >
                  <div
                    class="project__section__contributor__image"
                    [ngStyle]="{
                      backgroundImage: 'url(\'' + contributor.picture + '\')'
                    }"
                  >
                    <img
                      class="project__section__contributor__image__quotes"
                      src="assets/images/quotes.png"
                      alt=""
                    />
                  </div>
                  <div class="project__section__contributor__body">
                    <div class="project__section__contributor__body__name">
                      {{ contributor.firstName }} {{ contributor.lastName }}
                    </div>
                    <div class="project__section__contributor__body__quote">
                      {{ contributor.displayQuote }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </div>

        <div class="project__section">
          <div class="project__section__title">
            {{ "project.universities-title" | translate }}
          </div>
          <div class="project__section__separator">
            <img
              class="project-section__title__decoration"
              src="../../../assets/images/shape_line_section_project.svg"
            />
          </div>
          <div class="project__section__content">
            <p *ngIf="!projectUniversity; else universitiesContent">
              {{ "project.no-university-content" | translate }}
            </p>
          </div>
          <ng-template #universitiesContent>
            <div class="project__section__university__container">
              <ng-container *ngFor="let university of projectUniversity">
                <div
                  class="project__section__university"
                  (click)="selectUniversity(university)"
                  (click)="openUniversityWebsite(university)"
                >
                  <a
                    [href]="university.website"
                    target="_blank"
                    class="project__section__university__image__link"
                  >
                    <div
                      class="project__section__university__image"
                      [ngStyle]="{
                        'background-image': 'url(' + university.picture + ')'
                      }"
                    ></div>
                  </a>
                  <div class="project__section__university__body">
                    <a
                      class="project__section__university__body__name"
                      [href]="university.website"
                      target="_blank"
                    >
                      {{ university.displayName }}
                    </a>
                    <div class="project__section__university__body__faculty">
                      {{ university.faculty }}
                    </div>
                    <div class="project__section__university__body__location">
                      {{ university.displayCity }}
                      {{ university.displayCountry }}
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </div>

        <app-project-section
          class="project__section"
          *ngIf="projectSponsors"
          [sponsors]="projectSponsors"
        ></app-project-section>
      </mat-tab>
      <mat-tab label="{{ 'project.label-openData-title' | translate }}">
        <ng-container *ngIf="user_data; else signInAccess">
          <div class="project__tab__open-data">
            <ng-container
              *ngIf="openDataListDefinitions.length; else emptyList"
            >
              <ng-container *ngIf="openDataAccess; else noAccess">
                <ng-container
                  *ngFor="let openDatadefinition of openDataListDefinitions"
                >
                  <app-project-open-data-section
                    [definition]="openDatadefinition"
                  >
                  </app-project-open-data-section>
                </ng-container>
              </ng-container>
              <ng-template #noAccess>
                <span class="project__tab__open-data__message">
                  <ng-container *ngIf="!request_status">
                    <ng-container *ngIf="request_access_step === 1">
                      {{ "project.request-access-step-1" | translate }}
                      <button-loading
                        color="primary"
                        (click)="request_access_step = 2"
                      >
                        {{ "project.request-access-step-2" | translate }}
                      </button-loading>
                    </ng-container>

                    <ng-container *ngIf="request_access_step === 2">
                      <div class="project__tab__open-data__message__legal-text">
                        <h3 *ngIf="!!project?.displayLegalText">
                          {{ project.displayLegalTextTite }}
                        </h3>
                        {{ project.displayLegalText }}
                      </div>
                      <mat-checkbox
                        [(ngModel)]="request_legal_check"
                        color="primary"
                        >{{ "project.request-legal-check" | translate }}
                      </mat-checkbox>
                      <button-loading
                        [loading]="isProjectTokenLoading"
                        color="primary"
                        [disabled]="!request_legal_check"
                        (click)="requestOpenDataAccess()"
                      >
                        {{ "project.button-submit-request" | translate }}
                      </button-loading>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="request_status === 'rejected'">
                    {{ "project.request-rejected" | translate }}
                  </ng-container>

                  <ng-container *ngIf="request_status === 'pending'">
                    {{ "project.request-pending" | translate }}
                  </ng-container>
                </span>
              </ng-template>
            </ng-container>
            <ng-template #emptyList>
              <div
                class="project__tab__open-data__loading"
                *ngIf="open_data_wait_for_api"
              >
                <mat-spinner diameter="30"></mat-spinner>
              </div>
              <span
                class="project__tab__open-data__message"
                *ngIf="!open_data_wait_for_api"
              >
                {{ "project.data-no-available" | translate }}
              </span>
            </ng-template>
          </div>
        </ng-container>
        <ng-template #signInAccess>
          <span class="project__tab__open-data__message">
            {{ "project.request-signin" | translate }}
            <button-loading color="primary" [routerLink]="'/session'">
              {{ "project.button-request-signin" | translate }}
            </button-loading>
          </span>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <div class="project__opacity-background"></div>
  </ng-template>
</div>

<app-image-preview
  *ngIf="selectedScreenshot"
  (exit)="selectedScreenshot = undefined"
  [picture]="selectedScreenshot.picture"
  [description]="selectedScreenshot.description[translate.currentLang]"
></app-image-preview>

<app-contributor-card
  *ngIf="selectedContributor"
  (exit)="selectedContributor = undefined"
  [contributor]="selectedContributor"
>
</app-contributor-card>
