import { Injectable } from '@angular/core';
import { CollectionGenericLibService } from './collection-generic-lib.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Sponsors, SponsorsDTO } from '../models/sponsors.model';

@Injectable({
  providedIn: 'root',
})
export class SponsorsService extends CollectionGenericLibService<
  Sponsors,
  SponsorsDTO
> {
  COLLECTION_NAME = 'sponsors';
  projectSponsor: Sponsors[];

  constructor(public override firestore: AngularFirestore) {
    super(firestore);
    this.c = Sponsors;
    this.collectionName = this.COLLECTION_NAME;
  }
}
