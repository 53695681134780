import { Component, Input } from '@angular/core';

export interface FullWidthTitleDefinition {
  line1: string;
  line2: string;
}

@Component({
  selector: 'app-full-width-title',
  templateUrl: './full-width-title.component.html',
  styleUrls: ['./full-width-title.component.scss'],
})
export class FullWidthTitleComponent {
  @Input() definition: FullWidthTitleDefinition | undefined;
}
