import { inject, Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateFn,
} from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { map, Observable, of, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UnAuthGuardService implements CanActivate {
    constructor(private router: Router, private auth: AuthenticationService, private afa: AngularFireAuth) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.afa.authState.pipe(
            take(1),
            map(auth => {
                if (!!auth) {
                    return true;
                } else {
                    this.router.navigate(['/auth/login']);
                    return false;
                }
            })
        );
    }
}

export const OnBoardingGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(UnAuthGuardService).canActivate(next, state);
}