import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';

export type SessionMode =
  | 'login'
  | 'register'
  | 'verifyEmail'
  | 'forgetPassword'
  | 'resetPassword';
@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss'],
})
export class SessionComponent implements OnInit {
  mode: SessionMode = 'login';

  oobCode: string = null;

  constructor(private activatedRoute: ActivatedRoute) {}

  async ngOnInit() {
    const query_params = await firstValueFrom(this.activatedRoute.queryParams);

    if (!Object.keys(query_params).length) {
      return;
    }

    if (query_params['mode']) {
      this.mode = query_params['mode'];
    }

    if (query_params['oobCode']) {
      this.oobCode = query_params['oobCode'];
    }
  }
}
