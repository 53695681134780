import { Model } from './generic-model';
import { FirebaseDeserializable } from './FirebaseDeserializable';

export class UserDTO extends Model {
  public first_name?: string;
  public last_name?: string;
  public email?: string;
  public project_open_data_access?: string[];
  public full_name?: string;
  public language?: string;
  public newsletter?: boolean;
}

export class User extends UserDTO implements FirebaseDeserializable<UserDTO> {
  deserialize(input: UserDTO): this {
    Object.assign(this, input);
    return this;
  }

  constructor(input: any = {}) {
    super(input);
    return this.deserialize(input);
  }

  toJSON() {
    return Object.assign({}, this);
  }

  get fullName() {
    if (!this.first_name && !this.last_name) {
      return '-';
    }

    return `${!!this.first_name ? this.first_name : ''} ${
      !!this.last_name ? this.last_name : ''
    }`;
  }
}
