<div class="project-section" *ngIf="definition || sponsors">
  <div class="project-section__title">
    {{
      definition
        ? ("project.label.title" | translate)
        : ("project.sponsors.partner" | translate)
    }}
  </div>
  <div class="project-section__separator">
    <img
      class="project-section__title__decoration"
      src="../../../assets/images/shape_line_section_project.svg"
    />
  </div>
  <div class="project-section__content" [innerHTML]="definition"></div>
  <div class="project-section__gallery" *ngIf="sponsors">
    <p *ngIf="sponsors.length === 0">
      {{ "project.sponsors.no-partner" | translate }}
    </p>
    <ng-container *ngFor="let picture of sponsors">
      <a
        *ngIf="picture.website"
        [href]="picture.website"
        target="_blank"
        [matTooltip]="picture.displayName"
      >
        <img
          class="
            project-section__gallery__picture project-section__gallery__picture
          "
          [src]="picture.picture"
          alt=""
        />
      </a>

      <img
        [matTooltip]="picture.displayName"
        *ngIf="!picture.website"
        class="
          project-section__gallery__picture project-section__gallery__picture
        "
        [src]="picture.picture"
        alt=""
      />
    </ng-container>
  </div>
</div>

<app-contributor-card
  *ngIf="selectedContributor"
  [contributor]="selectedContributor"
  (exit)="selectedContributor = undefined"
></app-contributor-card>
