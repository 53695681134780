import { Component, Input } from '@angular/core';
import { Contributors } from 'src/app/models/contributors.model';
import { Sponsors } from 'src/app/models/sponsors.model';

@Component({
  selector: 'app-project-section',
  templateUrl: './project-section.component.html',
  styleUrls: ['./project-section.component.scss'],
})
export class ProjectSectionComponent {
  @Input() definition: string;
  @Input() sponsors: Sponsors[];
  selectedContributor: Contributors;
}
