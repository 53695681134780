import { Injectable } from '@angular/core';
import { CollectionGenericLibService } from './collection-generic-lib.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subject, firstValueFrom } from 'rxjs';
import {
  OpenDataToken,
  OpenDataTokenDTO,
} from '../models/open-data-token.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root',
})
export class OpenDataTokenService extends CollectionGenericLibService<
  OpenDataToken,
  OpenDataTokenDTO
> {
  COLLECTION_NAME = 'open-data-token';
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public override firestore: AngularFirestore,
    private fsFunction: AngularFireFunctions
  ) {
    super(firestore);
    this.c = OpenDataToken;
    this.collectionName = this.COLLECTION_NAME;
  }

  destroySubscriptions() {
    this.destroyGenericSubscriptions();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  createOpenDataToken(projectId: string): Promise<void> {
    return firstValueFrom(
      this.fsFunction.httpsCallable('createOpenDataToken')(projectId)
    );
  }
}
