import { Component, Output, EventEmitter } from '@angular/core';
import { SessionMode } from '../session.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation-reset',
  templateUrl: './confirmation-reset.component.html',
  styleUrls: ['./confirmation-reset.component.scss'],
})
export class ConfirmationResetComponent {
  @Output() switchMode: EventEmitter<SessionMode> =
    new EventEmitter<SessionMode>();

  constructor(private route: Router) {}

  onClick() {
    this.route.navigate(['/session']);
    this.switchMode.emit('login');
  }
}
