<div class="session">
  <ng-container *ngIf="mode === 'login'">
    <h1 class="session__title">{{ "session.signin.title" | translate }}</h1>
    <span class="session__description">{{
      "session.description-signin" | translate
    }}</span>
    <app-login (switchMode)="mode = $event"></app-login>
  </ng-container>

  <ng-container *ngIf="mode === 'register'">
    <h1 class="session__title">
      {{ "session.signin-register.title" | translate }}
    </h1>
    <app-register (switchMode)="mode = $event"></app-register>
  </ng-container>

  <ng-container *ngIf="mode === 'forgetPassword'">
    <h1 class="session__title session__title__forget">
      {{ "session.forget-password.title" | translate }}
    </h1>
    <span class="session__description session__description__forget">{{
      "session.forget-password.subtitle" | translate
    }}</span>
    <app-forget-password (switchMode)="mode = $event"></app-forget-password>
  </ng-container>

  <ng-container *ngIf="mode === 'resetPassword' && oobCode">
    <h1 class="session__title session__title__forget">
      {{ "session.reset-password.title" | translate }}
    </h1>
    <span class="session__description session__description__forget">{{
      "session.reset-password.subtitle" | translate
    }}</span>
    <app-reset-password
      [oobCode]="oobCode"
      (switchMode)="mode = $event"
    ></app-reset-password>
  </ng-container>

  <ng-container *ngIf="mode === 'verifyEmail' && oobCode">
    <h1 class="session__title">
      {{ "session.email-verification-title" | translate }}
    </h1>
    <app-email-verified [oobCode]="oobCode" (switchMode)="mode = $event">
    </app-email-verified>
  </ng-container>
</div>
