import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from '../../../services/authentication.service';
import { firstValueFrom } from 'rxjs';
import { InternationalizedService } from 'src/app/services/internationalized.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user.model';
@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
  showTeamPage = environment.showTeamPage;
  current_profile: User = undefined;
  langageText = '';
  currentLocale = '';
  isAdmin = false;

  constructor(
    private auth: AuthenticationService,
    private internationalizedService: InternationalizedService,
    private translate: TranslateService
  ) {
    this.initCurrentUser();
    this.auth.profileChange.subscribe((newProfile) => {
      this.current_profile = newProfile;
    });
    this.currentLocale = InternationalizedService.getLocale();
    this.translate.get(this.currentLocale).subscribe((translation: string) => {
      this.langageText = translation.toUpperCase();
    });
    this.auth.isAdmin().then((status) => {
      this.isAdmin = status;
    });
  }
  ngOnInit(): void {
    this.translate.use(this.currentLocale);
  }
  toggleLanguage(lang: string) {
    this.internationalizedService.setLocale(lang);
    this.updateLangageDisplay(lang);
  }
  updateLangageDisplay(lang: string) {
    this.translate.use(lang);

    this.translate.get(lang).subscribe((translation: string) => {
      this.langageText = translation.toUpperCase();
    });
  }
  async initCurrentUser() {
    const user_data = await firstValueFrom(this.auth.currentProfile$);
    if (!user_data) {
      this.current_profile = null;
      return;
    }

    this.current_profile = user_data;
  }

  logout() {
    this.auth.logout();
  }
}
