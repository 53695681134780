


/**
 * USAGE
### YYYY-MM-DD as default.
const dateFormat = formatDate(new Date("2021-03-17T13:00"));

### a specified format as we can specify a format as second arguement
const dateFormat = formatDate(new Date("2021-03-17T13:00"), "DD-MMM-YYYY"); -> 18-Mar-2021

### To get time format as 24 hrs or 12 hrs
const formattedTime = getTime(new Date("2021-03-17T13:00"), false);
 */

import { throwError } from "rxjs";


// returns the date as `2021-03-31T12:21`
export function format(date, startOfDay?) {
  const fDate = formatDate((date));
  const fTime = startOfDay ? '00:00' : getTime(date, true, true);
  return fDate + "T" + fTime;
}

// this function returns Date as `202103311221`  <-- (2021-03-31T12:21)
export function deFormat(date) {
  const fDate = formatDate(date);
  const fTime = getTime(date, true, true);
  return (fDate + fTime).replace(/[:-]/g, "");
}

export function formatDate(date, dateFormat?, delimiter?) {
  date = new Date(new Date(date).toUTCString());
  if (!dateFormat) {
    dateFormat = "YYYY-MM-DD";
  }
  if (!delimiter) {
    delimiter = "-";
  }
  const formattedDate = dateFormat.split(delimiter).map((frmt) => {
    if (frmt) {
      return getFormatValue(frmt, date);
    }
  });

  return formattedDate.join(delimiter);
}

export function getFormatValue(dateFormat, date) {
  const monthNum = date.getMonth() + 1;
  switch (dateFormat) {
    case "YYYY":
    case "yyyy":
      const year = date.getFullYear();
      return year;

    case "MM":
    case "mm":
      const month = (monthNum < 10) ? "0" + monthNum.toString() : monthNum;
      // monthNum && monthNum.toString().length > 1 ? monthNum : "0" + monthNum;
      return month;

    case "MMM":
      const upperCase = true;
      const mon = getMonthString(date.getMonth(), upperCase);
      return mon;

    case "mmm":
      const Mon = getMonthString(date.getMonth());
      return Mon;

    case "DD":
    case "D":
    case "d":
    case "dd":
      const day = (date.getDate() < 10) ? "0" + date.getDate().toString() : date.getDate();
      return day;

    case "ddd":
    case "DDD":
      const dayStr = getDayAsString(date.getDay());
      return dayStr;
  }
}

export function getMonthString(monthIndex, firstCharCap?) {
  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  // if (firstCharCap) {
  //   months = ["jan", "feb", "mar", "apr", "may", "jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  // }
  return months[monthIndex];
}

export function getDayAsString(index) {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return days[index];
}

export const Period = "days" || "hours" || "minutes" || "seconds";


export function add(date, numberOfDaysToAdd: number, period: typeof Period) {
  // Fix for DST
  // Ignore the time (time reacts on DST. i.e 2021-10-31 00:00 retuns 2021-10-30 23:00)
  // Considering only the date
  if (period === "days") {
    // const dateToAdd = formatDate(date);
    // const currentDayTime = new Date(dateToAdd).getTime();
    // const timeToAdd = numberOfDaysToAdd * 24 * 60 * 60 * 1000;
    // const dd = currentDayTime + timeToAdd;
    // const ee = new Date(dd);
    // return ee;
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + numberOfDaysToAdd);
    return currentDate;
  } else if (period === "hours") {
    return new Date(new Date(date).getTime() + (numberOfDaysToAdd * 60 * 60 * 1000));
  } else if (period === "minutes") {
    return new Date(new Date(date).getTime() + (numberOfDaysToAdd * 60 * 1000));
  } else if (period === "seconds") {
    return new Date(new Date(date).getTime() + (numberOfDaysToAdd * 1000));
  }

  return date;
}

export function sub(date, numberOfDaysToAdd: number, period: typeof Period) {
  if (period === "days") {
    return new Date(new Date(date).getTime() - (numberOfDaysToAdd * 24 * 60 * 60 * 1000));
  } else if (period === "hours") {
    return new Date(new Date(date).getTime() - (numberOfDaysToAdd * 60 * 60 * 1000));
  } else if (period === "minutes") {
    return new Date(new Date(date).getTime() - (numberOfDaysToAdd * 60 * 1000));
  } else if (period === "seconds") {
    return new Date(new Date(date).getTime() - (numberOfDaysToAdd * 1000));
  }

  return date;
}

export function diff(date1, date2, period: typeof Period) {
  switch (period) {
    case "days":
      return Math.abs(date2 - date1) / (1000 * 60 * 60 * 24);

    case "hours":
      return Math.abs(date2 - date1) / (1000 * 60 * 60);

    case "minutes":
      return Math.abs(date2 - date1) / (1000 * 60);

    case "seconds":
      return Math.abs(date2 - date1) / (1000);
  }
}

export function isSameDay(date1, date2) {
  const formatedDate1 = formatDate(date1);
  const formatedDate2 = formatDate(date2);
  return formatedDate1 === formatedDate2;
}

// set leadingZero for Hour only, not for minutes
export function getTime(date, is24Hrs, leadingZero?, noAmPm?) {
  const hr = new Date(date).getHours();
  const mins = new Date(date).getMinutes();
  const roundMins = getDigitalTimeLeadingZero(mins, true, is24Hrs);
  const time =
    is24Hrs ? getDigitalTimeLeadingZero(hr, leadingZero, is24Hrs) + ":" + roundMins :
      hr > 12 ?
        getDigitalTimeLeadingZero(hr - 12, leadingZero, is24Hrs) + ":" + roundMins + (!noAmPm ? " pm" : "") :
        getDigitalTimeLeadingZero(hr, leadingZero, is24Hrs) + ":" + roundMins + (!noAmPm ? (hr >= 12) ? " pm" : " am" : "");


  return time;
}

// exports 9:00 -> 09:00
export function getDigitalTimeLeadingZero(timeInNum, leadingZero?, is24Hrs?) {
  if (!leadingZero) {
    if (!is24Hrs && timeInNum === 0) {
      return 12;
    }
    return timeInNum;
  }
  return timeInNum.toString().length > 1 ? timeInNum : "0" + timeInNum;
}

export function valueOf(date) {
  return new Date(date).valueOf();
}

export function startOfValue(date) {
  const dateObj = new Date(date);
  dateObj.setHours(0, 0, 0, 0);
  return dateObj.valueOf();
}


export const startOfDays = "day" || "week" || "isoWeek";

export function startOf(date, timeLine: typeof startOfDays) {
  const dateObj = new Date(date);
  const day = dateObj.getDay();
  let dateDiff;
  if (timeLine === "week") { // BOOKING_CALENDAR_START_DAY.sunday
    dateDiff = dateObj.getDate() - day;
  } else if (timeLine === "isoWeek") {
    dateDiff = dateObj.getDate() - day + (day === 0 ? -6 : 1);
  } else {
    return new Date(startOfValue(date));
  }

  return startOfValue(new Date(dateObj.setDate(dateDiff)));
}

export function endfMonth(date) {
  const dateNow = new Date(date);
  // const firstDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
  const lastDate = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);
  return lastDate;
}

export function getTotalMinutes(date) {
  const dd = new Date(date);
  const hrMins = dd.getHours() * 60;
  const mins = dd.getMinutes();
  return hrMins + mins;
}

export function getTimeFromMins(minutes, is24Hours?) {
  const hour = Math.floor(minutes / 60);
  const hours = hour.toString().length > 1 ? hour : "0" + hour;
  // if(!is24Hours) {
  //   hours = hour.toString().length > 1 ? hour - 12 : "0" + hour;
  // }
  const min = minutes % 60;
  const mins = min.toString().length > 1 ? min : "0" + min;

  return hours + ":" + mins;


  /**
   * TODO:
   * Incase of we need 12 hours format
   * if(hour > 12){
        ext = "PM";
        hour = (hour - 12);
        result = hour;

        if(hour < 10){
            result = "0" + hour;
        }else if(hour == 12){
            hour = "00";
            ext = "AM";
        }
    }
    else if(hour < 12){
        result = ((hour < 10) ? "0" + hour : hour);
        ext = "AM";
    }else if(hour == 12){
        ext = "PM";
    }
   */
}


/**
 * Expects a timezone and
 * @param timezone timezone
 * @returns timezone with offset
 */
export function getTimezoneOffsetDST(timezone) {
  try {
    const date = new Date();
    // undefined -> locale string
    // if we give "en-US", the outcome will be "date, hour:mins" -> date<comma><space>hour:month
    // if we give "nl-NL", the outcome will be "date hour:mins" ->  date<space>hour:month
    // undefined returns same as en-US
    let localeString = date.toLocaleString(undefined, { timeZone: timezone, day: "numeric", hour: "numeric", minute: "numeric", hour12: false });
    const localeTZ = objFromStr(localeString);
    localeString = date.toLocaleString(undefined, { day: "numeric", hour: "numeric", minute: "numeric", hour12: false });
    const currentLocale = objFromStr(localeString);
    const amsterdamOffset = (localeTZ.day * 24 * 60) + (localeTZ.hour * 60) + (localeTZ.minute);
    const currentLocaleOffset = (currentLocale.day * 24 * 60) + (currentLocale.hour * 60) + (currentLocale.minute);
    const offsetInMins = currentLocaleOffset - amsterdamOffset + date.getTimezoneOffset();
    const prefixPlusSign = offsetInMins < 0 ? "+" : "";
    const offsetInFormat = offsetInMins === 0 ? 0 : getTimeFromMins(-offsetInMins);
    const offset = offsetInFormat;
    const timezoneString = timezone + " (UTC" + (offset === 0 ? "" : prefixPlusSign + offset) + ")";
    return timezoneString;
  } catch (error) {
    return null;
  }
}

export function objFromStr(localeString) {
  // Since locale is undefined, the format will be "date, hour:mins"
  // So have to replace the comma and colon
  // the array will have [date, hour, min]
  const array = localeString.replace(":", " ").replace(",", "").split(" ");
  return {
    day: parseInt(array[0], 10),
    hour: parseInt(array[1], 10),
    minute: parseInt(array[2], 10)
  };
}

export function getFirstLastDateOfMonth(dateObject) {
  const date = new Date(dateObject);
  const firstDateOfMonth = format(new Date(date.getFullYear(), date.getMonth(), 1));
  const lastDateOfMonth = format(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  return {
    firstDateOfMonth,
    lastDateOfMonth
  };
}

export function isSameOrAfter(date1, date2) {
  return (new Date(date1) === new Date(date2)) || (new Date(date1) > new Date(date2));
}

export function isAfter(date1, date2) {
  return (new Date(date1) > new Date(date2));
}

export function isSameOrBefore(date1, date2) {
  return (new Date(date1) === new Date(date2)) || (new Date(date1) < new Date(date2));
}

export function isBefore(date1, date2) {
  return (new Date(date1) < new Date(date2));
}

export function removeOffset(date) {
  if (!date) {
    return false;
  }
  const formatedDateObject = new Date(date);

  return new Date(
    formatedDateObject.getTime() +
    Math.abs(formatedDateObject.getTimezoneOffset() * 60000)
  );
}



export function getDateFormatString(locale) {
  try {
    const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());
    return formatObj
      .map(obj => {
        switch (obj.type) {
          case "day":
            return "DD";
          case "month":
            return "MM";
          case "year":
            return "YYYY";
          default:
            return obj.value;
        }
      })
      .join("");
  } catch (e) {
    throwError(e);
  }

  return locale;
}


export function findTheDelimiterOfLocaleFormat(locale) {
  try {
    const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());
    const literalObj = formatObj.find(obj => obj.type === "literal");
    return literalObj.value;
  } catch (error) {
    throwError(error);
  }

  return locale;
}


