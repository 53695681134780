import { Injectable } from '@angular/core';
import { CollectionGenericLibService } from './collection-generic-lib.service';
import { Contributors, ContributorsDTO } from '../models/contributors.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class ContributorsService extends CollectionGenericLibService<
  Contributors,
  ContributorsDTO
> {
  COLLECTION_NAME = 'contributors';

  constructor(public override firestore: AngularFirestore) {
    super(firestore);
    this.c = Contributors;
    this.collectionName = this.COLLECTION_NAME;
  }
}
