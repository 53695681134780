import { FirebaseDeserializable } from './FirebaseDeserializable';
import { Model } from './generic-model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { InternationalizedService } from '../services/internationalized.service';

export type level = 'Associate Director' | 'Executive Committee';
export class ResearcherDTO extends Model {
  //What Firebase should return from the contributors document
  public picture?: string;
  public firstName?: string;
  public lastName?: string;
  public title: { en: string; fr: string; es: string };
  public faculty?: string;
  public university?: string;
  public projectIds?: string[];
  public instituteIds?: string[];
  public level: level;
}

export class Researchers
  extends ResearcherDTO
  implements FirebaseDeserializable<ResearcherDTO>
{
  deserialize(input: ResearcherDTO): this {
    Object.assign(this, input);
    return this;
  }

  get displayTitle() {
    const lang = InternationalizedService.getLocale();
    return this.title[lang].split('\\n');
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
