<div class="institute-section" *ngIf="title">
  <div class="institute-section__title">
    {{ title }}
  </div>
  <div class="institute-section__separator">
    <img
      class="institute-section__title__decoration"
      src="../../../assets/images/shape_line_section_project.svg"
      alt="separator"
    />
  </div>
  <div class="institute-section__content" [innerHTML]="content"></div>
</div>
