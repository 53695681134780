<div class="news-card">
  <div class="news-card__content">
    <span *ngIf="newsData.source" class="news-card__content__source">{{
      newsData.source
    }}</span>
    <h2 class="news-card__content__title">{{ newsData.displayTitle }}</h2>
    <p class="news-card__content__description">
      {{ newsData.displayDescription }}
    </p>
    <div class="news-card__content__footer">
      <div class="news-card__content__footer__left">
        <span
          *ngIf="newsData.date"
          class="news-card__content__footer__left__text"
        >
          {{ newsData.displayDate }}
        </span>
        <span
          *ngIf="newsData.defaultLang"
          class="news-card__content__footer__left__text"
        >
          {{ newsData.defaultLang }}
        </span>
        <span
          *ngIf="newsData.project"
          class="news-card__content__footer__left__project"
          ><a
            *ngIf="newsData.project"
            [href]="newsData.project.link"
            target="_blank"
          >
            {{ newsData.displayProjectName }}
          </a>
        </span>
      </div>
      <div *ngIf="newsData.link" class="news-card__content__footer__link">
        <a *ngIf="newsData.link" [href]="newsData.link" target="_blank">
          {{ "news-card.read-more" | translate }}
        </a>
      </div>
    </div>
  </div>
  <div class="news-card__image-container">
    <img class="news-card__image-container__image" [src]="imageUrl" alt="" />
  </div>
</div>
