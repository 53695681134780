import { Component } from '@angular/core';
import { OpenDataTokenService } from '../../services/open-data-token.service';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
@Component({
  selector: 'app-open-data-access',
  templateUrl: './open-data-access.component.html',
  styleUrls: ['./open-data-access.component.scss'],
})
export class OpenDataAccessComponent {
  message = '-';

  constructor(
    private activatedRoute: ActivatedRoute,
    private openDataTokenService: OpenDataTokenService
  ) {
    this.initAccess();
  }

  async initAccess() {
    const query_params = await firstValueFrom(this.activatedRoute.queryParams);

    if (!Object.keys(query_params).length) {
      this.message = _('open-data-access.no-access-page');
      return;
    }

    if (!query_params?.['token'] || !query_params?.['status']) {
      this.message = _('open-data-access.invalid-access-token');
      return;
    }

    try {
      const token = await firstValueFrom(
        this.openDataTokenService.getData(query_params?.['token'])
      );

      if (!token) {
        this.message = _('open-data-access.invalid-access-token');
      } else {
        await this.openDataTokenService
          .set(
            query_params?.['token'],
            Object.assign(
              {},
              {
                status: query_params?.['status'],
              }
            ) as any
          )
          .then(() => {
            switch (query_params?.['status']) {
              case 'rejected': {
                this.message = _('open-data-access.reject-request');
                break;
              }
              case 'accepted': {
                this.message = _('open-data-access.accept-request');
                break;
              }
            }
          });
      }
    } catch (e) {
      console.error(e);
      this.message = _('open-data-access.invalid-access-token');
    }
  }
}
