import { DateTime } from 'luxon';
import { environment } from '../../environments/environment';

export class Model {
  id?: string;
  ref?: any;

  constructor(data: any = {}) {
    for (const name in data) {
      if (data.hasOwnProperty(name)) {
        this[name] = data[name];
      }
    }
  }

  convertToJSDate?(timeStamp) {
    if (timeStamp instanceof Date) {
      return timeStamp;
    }

    if (typeof timeStamp === 'string') {
      return new Date(timeStamp);
    }

    if (timeStamp?._seconds) {
      return new Date(timeStamp._seconds * 1000);
    }

    if (timeStamp?.seconds) {
      return new Date(timeStamp.seconds * 1000);
    }

    if (timeStamp?.toDate) {
      return timeStamp.toDate();
    }

    return null;
  }

  formatTimeStampDate?(timeStamp, format: string) {
    const isValidDate = timeStamp instanceof Date;
    const date = isValidDate ? timeStamp : this.convertToJSDate(timeStamp);

    return DateTime.fromJSDate(date).setLocale(`en-CA`).toFormat(format);
  }

  locale?() {
    const locale = localStorage.getItem('locale');
    return !!locale ? JSON.parse(locale) : 'fr';
  }

  formatPriceLocaleString?(amount: number) {
    return amount.toLocaleString(`${this.locale()}-ca`, {
      style: 'currency',
      currency: 'CAD',
    });
  }

  formatHours?(hoursInSeconds: number) {
    const UNIX_TIME_MULTIPLIER = 1000;

    return new Date(hoursInSeconds * UNIX_TIME_MULTIPLIER).toLocaleTimeString(
      `${this.locale()}-CA`,
      {
        timeZone: 'UTC',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }
    );
  }

  get fireStorageUrl() {
    return environment.useEmulators
      ? 'http://localhost:9199'
      : 'https://firebasestorage.googleapis.com';
  }
}
