import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-institute-page-section',
  templateUrl: './institute-page-section.component.html',
  styleUrls: ['./institute-page-section.component.scss'],
})
export class InstitutePageSectionComponent {
  @Input() title: string;
  @Input() content: string;
}
