import { Component, Input, OnInit } from '@angular/core';
import { News } from '../../../models/news.model';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
})
export class NewsCardComponent implements OnInit {
  @Input() newsData: News;
  imageUrl: string;

  constructor(private storage: AngularFireStorage) {}

  ngOnInit(): void {
    this.storage
      .ref(this.newsData.image)
      .getDownloadURL()
      .subscribe((url) => {
        this.imageUrl = url;
      });
  }
}
