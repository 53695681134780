<div class="admin-current-access">
  <div class="admin-current-access__container">
    <p class="admin-current-access__container__title">
      {{ title }}
    </p>
    <div class="admin-current-access__container__table mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">
        <ng-container
          *ngFor="let column of columns; let last = last"
          [matColumnDef]="column.key"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let element">
            <!-- Check if it's the last column -->
            <ng-container *ngIf="last && column.key === 'lastColumnKey'">
              <button
                mat-mini-fab
                matTooltip="Reject"
                color="warn"
                [disabled]="isLoading"
                (click)="onRemove(element)"
              >
                <mat-icon>close</mat-icon>
                <mat-spinner
                  *ngIf="isLoading"
                  style="height: 20px"
                ></mat-spinner>
              </button>
            </ng-container>
            <!-- Display other column data -->
            <ng-container *ngIf="!last || column.key !== 'lastColumnKey'">
              {{ element[column.key] }}
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()"></tr>
        <tr *matNoDataRow>
          <td
            class="admin-current-access__container__table__empty-text"
            [attr.colspan]="getDisplayedColumns()"
          >
            The current access table is empty.
          </td>
        </tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        showFirstLastButtons
        [pageSize]="20"
      >
      </mat-paginator>
    </div>
  </div>
</div>
