<button
  mat-flat-button
  [color]="color"
  [type]="type"
  [disabled]="loading || disabled"
  [ngClass]="{ loading: loading }"
>
  <div class="btn-spinner" *ngIf="loading"></div>
  <span *ngIf="!loading" style="color: white">
    <ng-content></ng-content>
  </span>
  <span *ngIf="loading" style="color: #548c2f">{{ loadingText }}</span>
</button>
