<div class="sustainability-directory">
  <div class="sustainability-directory__hero">
    <div class="sustainability-directory__hero__background">
      <div class="sustainability-directory__hero__background__layer"></div>
      <div class="sustainability-directory__hero__background__fade-layer"></div>
      <video
        class="sustainability-directory__hero__background__video"
        [autoplay]="true"
        [muted]="true"
        [loop]="true"
        [playbackRate]="0.7"
      >
        <source src="../../../assets/videos/homepage.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="sustainability-directory__hero__title">
      <img
        class="sustainability-directory__hero__title__image"
        src="../../../../assets/images/shape_title_about_us.svg"
        alt="Hero Title"
      />
      <div class="sustainability-directory__hero__title__text">
        {{ "sustainability-directory-page.title" | translate }}
      </div>
    </div>

    <div class="sustainability-directory__hero__subtitle">
      {{ "sustainability-directory-page.subtitle" | translate }}
    </div>
    <app-dots class="sustainability-directory__hero__dots"></app-dots>
  </div>

  <app-full-width-title
    [definition]="sustainbilityTitleDefinition"
    class="sustainability-directory__title"
  ></app-full-width-title>

  <mat-spinner *ngIf="isLoading; else loadedContent"></mat-spinner>
  <ng-template #loadedContent>
    <!-- Add the search bar section -->
    <div class="sustainability-directory__search">
      <div class="sustainability-directory__search__options">
        <mat-form-field appearance="outline">
          <mat-label>Country</mat-label>
          <mat-select [(ngModel)]="searchTerms.country" name="country">
            <mat-option value="">All countries</mat-option>
            <mat-option *ngFor="let country of countries" [value]="country">
              {{ country }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>University</mat-label>
          <mat-select [(ngModel)]="searchTerms.universities" name="university">
            <mat-option value="">All universities</mat-option>
            <mat-option
              *ngFor="let university of universities"
              [value]="university"
            >
              {{ university }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Themes</mat-label>
          <mat-select [(ngModel)]="searchTerms.categories" name="categories">
            <mat-option value="">All themes</mat-option>
            <mat-option
              *ngFor="let category of categories"
              [value]="category"
              >{{ category }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Subtheme</mat-label>
          <mat-select
            [(ngModel)]="searchTerms.subcategories"
            name="subcategories"
          >
            <mat-option value="">All subthemes</mat-option>
            <mat-option
              *ngFor="let subcategory of subcategories"
              [value]="subcategory"
              >{{ subcategory }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <button mat-raised-button color="primary" (click)="onSearch()">
        {{ "sustainability-directory-page.search" | translate }}
      </button>
    </div>

    <!-- Add the map section -->
    <div class="sustainability-directory__map">
      <agm-map
        [latitude]="lat"
        [longitude]="lng"
        [zoom]="zoom"
        [styles]="styles"
      >
        <agm-marker
          *ngFor="let institute of filteredInstitutes"
          [latitude]="institute.latitude"
          [longitude]="institute.longitude"
          [iconUrl]="iconUrl"
        >
          <agm-info-window>
            {{ institute.name }}<br />
            {{ institute.country }}<br />
            {{ institute.universities }}
          </agm-info-window>
        </agm-marker>
      </agm-map>
    </div>

    <!-- Add the results section -->
    <div class="sustainability-directory__results">
      <div class="sustainability-directory__results__title">
        {{ "sustainability-directory-page.institutes-list" | translate }} ({{
          filteredInstitutes.length
        }}
        {{ "sustainability-directory-page.results" | translate | lowercase }})
      </div>
      <div class="sustainability-directory__results__institutes">
        <ng-container *ngFor="let institute of filteredInstitutes">
          <div
            class="sustainability-directory__results__institutes__institute"
            (click)="redirectToInstitute(institute)"
          >
            <img
              class="
                sustainability-directory__results__institutes__institute__image
              "
              [src]="institute.logo"
              alt=""
            />
            <div
              class="
                sustainability-directory__results__institutes__institute__title
              "
            >
              {{ institute.displayName }}
            </div>
            <div
              class="
                sustainability-directory__results__institutes__institute__university_list
              "
            >
              <span>
                <p
                  *ngFor="let university of institute.universities"
                  class="
                    sustainability-directory__results__institutes__institute__university_list__university
                  "
                >
                  {{ university }}
                </p>
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</div>
