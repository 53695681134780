import { inject, Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateFn,
} from '@angular/router';
import { AuthenticationService } from '../services/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from, map, Observable, of, switchMap, take } from 'rxjs';
import { User } from '../models/userInfo.model';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    constructor(private router: Router, private auth: AuthenticationService, private afa: AngularFireAuth) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.afa.authState.pipe(
            take(1),
            switchMap(auth => {
                if (!!auth) {
                    return this.auth.getUser().pipe(
                        take(1),
                        map((user: User) => {
                            if (user?.entityId) {
                                this.router.navigate(['/']);
                                return false;
                            } else {
                                return true;
                            }
                        })
                    );
                } else {
                    return of(true);
                }
            })
        );
    }
}

export const OnBoardingGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(AuthGuardService).canActivate(next, state);
}