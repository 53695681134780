import { Inject, Injectable } from '@angular/core';
import { Observable, map, Subject, from } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentReference,
  DocumentSnapshot,
  Query,
  QueryDocumentSnapshot,
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import FieldPath = firebase.firestore.FieldPath;
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import OrderByDirection = firebase.firestore.OrderByDirection;
import { takeUntil } from 'rxjs/operators';
import {
  getCountFromServer,
  collection,
  where,
  query,
  orderBy,
} from '@angular/fire/firestore';
import { FirebaseDeserializable } from '../models/FirebaseDeserializable';
import { Model } from '../models/generic-model';

export interface FilterSetValue {
  comparator: WhereFilterOp;
  value: any;
}

/* use double underscore __[N] to query with the same field. [N] is a placeholder number that must be unique
 *Ex.
 *{field__1: {...}, field__2: {...}}
 * */
export interface FilterSet {
  [field: string]: FilterSetValue;
}

/*use double underscore __ instead of .dot notation for ordering by Object's subObject field
 *Ex.
 *{'customerDetails__name': 'desc'} | ✔ CORRECT
 *{'customerDetails.name': 'desc'} | ❌ WRONG
 * */
export interface OrderByFilter {
  [field: string]: OrderByDirection;
}

export interface IFirebaseQuery<DTO> {
  filters?: FilterSet;
  orderBy?: OrderByFilter;
  startAfter?: QueryDocumentSnapshot<DTO>;
  limit?: number;
}

export interface IPaginatedData<T, DTO> {
  next: IFirebaseQuery<DTO>;
  data: T[];
}

@Injectable({
  providedIn: 'root',
})
export class CollectionGenericLibService<
  T extends FirebaseDeserializable<DTO>,
  DTO extends Model
> {
  public collectionName: string;
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  // Class déclaration from type
  c: new () => T;

  get collection(): AngularFirestoreCollection<T> {
    return this.firestore.collection<T>(this.collectionName);
  }

  constructor(@Inject(AngularFirestore) public firestore: AngularFirestore) { }

  list(
    filters?: FilterSet,
    orderBy?: OrderByFilter,
    limit?: number,
    startAfter?: QueryDocumentSnapshot<DTO>
  ) {
    const hasFilter = !!filters && Object.keys(filters).length > 0;
    const hasOrdering = !!orderBy && Object.keys(orderBy).length > 0;
    const filterFunction = (ref): Query => {
      let query: Query;

      if (hasFilter) {
        Object.keys(filters).forEach((fieldKey) => {
          const field = fieldKey.split('__')[0];
          const filterValue = filters[fieldKey];
          if (query) {
            query = query.where(
              field,
              filterValue.comparator,
              filterValue.value
            );
          } else {
            query = ref.where(field, filterValue.comparator, filterValue.value);
          }
        });
      }

      if (hasOrdering) {
        Object.keys(orderBy).forEach((field) => {
          const fieldOrdering = new FieldPath(...field.split('__'));
          const filterValue: OrderByDirection = orderBy[field];
          if (query) {
            query = query.orderBy(fieldOrdering, filterValue);
          } else {
            query = ref.orderBy(fieldOrdering, filterValue);
          }
        });
      }

      if (!!startAfter) {
        query = query.startAfter(startAfter);
      }

      if (!!limit) {
        query = query.limit(limit);
      }

      return query;
    };

    let collRef: AngularFirestoreCollection;
    if (hasFilter || hasOrdering) {
      collRef = this.firestore.collection<DTO>(
        this.collectionName,
        filterFunction
      );
    } else {
      collRef = this.firestore.collection<DTO>(this.collectionName);
    }
    return collRef.snapshotChanges().pipe(takeUntil(this._destroy$));
  }

  listData(
    filters?: FilterSet,
    orderBy?: OrderByFilter,
    limit?: number,
    startAfter?: QueryDocumentSnapshot<DTO>
  ): Observable<T[]> {
    return this.list(filters, orderBy, limit, startAfter).pipe(
      map((docChanges: DocumentChangeAction<DTO>[]) => {
        return docChanges.map((docChange: DocumentChangeAction<DTO>) => {
          const data = docChange.payload.doc.data();
          data.id = docChange.payload.doc.id;
          data.ref = docChange.payload.doc;

          return new this.c().deserialize(data);
        });
      }),
      takeUntil(this._destroy$)
    );
  }

  getValueChanges(objectId: string) {
    return this.firestore
      .doc(this.collectionName + '/' + objectId)
      .valueChanges()
      .pipe(takeUntil(this._destroy$));
  }

  get(objectId: string): Observable<DTO | undefined> {
    return this.firestore
      .doc<DTO>(this.collectionName + '/' + objectId)
      .valueChanges()
      .pipe(takeUntil(this._destroy$));
  }

  getData(objectId: string): Observable<T> {
    return this.get(objectId).pipe(
      map((docChange: DTO) => {
        if (!!docChange) {
          docChange.id = objectId;
          return new this.c().deserialize(docChange);
        }
        return null;
      }),
      takeUntil(this._destroy$)
    );
  }

  async getCollectionCount(filters?: FilterSet, order_by?: OrderByFilter) {
    const hasFilter = !!filters && Object.keys(filters).length > 0;
    const hasOrdering = !!order_by && Object.keys(order_by).length > 0;
    const queryFilters = [];

    if (hasFilter) {
      Object.keys(filters).forEach((fieldKey) => {
        const field = fieldKey.split('__')[0];
        const filterValue = filters[fieldKey];
        queryFilters.push(
          where(field, filterValue.comparator, filterValue.value)
        );
      });
    }

    if (hasOrdering) {
      Object.keys(order_by).forEach((field) => {
        const fieldOrdering = new FieldPath(...field.split('__'));
        const filterValue: OrderByDirection = order_by[field];
        queryFilters.push(orderBy(fieldOrdering, filterValue));
      });
    }

    const coll = collection(this.firestore.firestore, this.collectionName);
    const q = query(coll, ...queryFilters);

    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
  }

  create(object: T | DTO) {
    return this.firestore.collection(this.collectionName).add({ ...object });
  }

  createData(object: DTO): Promise<T> {
    return this.create(object).then((docRef: DocumentReference) => {
      return docRef.get().then((docSnapshot: DocumentSnapshot<DTO>) => {
        const data = docSnapshot.data();
        data.id = docSnapshot.id;
        return new this.c().deserialize(data);
      });
    });
  }

  createDoc(collectionName, object: any) {
    return this.firestore
      .collection(collectionName)
      .add(object);
  }

  getDocumentByAttribute(collection, attribute, value) {
    return this.firestore.collection(collection).doc()
  }

  createWithId(uid: string, object: any) {
    return this.firestore
      .collection(this.collectionName)
      .doc(uid)
      .set({ ...object });
  }

  update(id: string, object: T | DTO) {
    return this.firestore
      .doc(this.collectionName + '/' + id)
      .update({ ...object });
  }

  set(id: string, object: T | DTO) {
    return this.firestore
      .doc(this.collectionName + '/' + id)
      .set(object, { merge: true });
  }

  delete(objectId: string) {
    return this.firestore.doc(this.collectionName + '/' + objectId).delete();
  }

  destroyGenericSubscriptions() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
