import { Model } from './generic-model';
import { FirebaseDeserializable } from './FirebaseDeserializable';
import { Timestamp } from 'firebase/firestore';
import { Project } from './project-list.model';
import { User } from './user.model';

export type OpenDataTokenStatus = 'accepted' | 'rejected' | 'pending';

export class OpenDataTokenDTO extends Model {
  public created_at?: any;
  public expire_at?: any;
  public user_id?: string;
  public project_id?: string;
  public project_data?: Project;
  public user_data?: User;
  public status?: OpenDataTokenStatus;

  public user?: string;
}

export class OpenDataToken
  extends OpenDataTokenDTO
  implements FirebaseDeserializable<OpenDataTokenDTO>
{
  deserialize(input: OpenDataToken): this {
    Object.assign(this, input);
    return this;
  }

  constructor(input: any = {}) {
    super(input);
    if (input.hasOwnProperty('project_data')) {
      this.project_data = new Project(input.project_data);
    }
    if (input.hasOwnProperty('user_data')) {
      this.user_data = new User(input.user_data);
    }
    return this.deserialize(input);
  }

  get displayStatus() {
    return this.status === 'pending' ? 'Pending to access' : 'Loading';
  }

  get displayName() {
    return this.user_data.full_name;
  }

  get displayEmail() {
    return this.user_data.email;
  }

  get displayTitleAdmin() {
    return this.project_data.title.en;
  }

  get displayCreatedAt() {
    const date = new Timestamp(
      this.created_at.seconds,
      this.created_at.nanoseconds
    );
    return date.toDate().toDateString();
  }
}
