<div class="image-preview">
  <div class="image-preview__layer" (click)="exit.emit()"></div>

  <div class="image-preview__body">
    <img
      (click)="exit.emit()"
      class="image-preview__body__picture"
      [src]="picture"
      alt=""
    />
    <div class="image-preview__body__text" *ngIf="description">
      {{ description }}
    </div>
  </div>
</div>
