import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { SessionMode } from '../session.component';

@Component({
  selector: 'app-firebase-email-action',
  templateUrl: './firebase-email-action.component.html',
  styleUrls: ['./firebase-email-action.component.scss'],
})
export class FirebaseEmailActionComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((parmaMap: ParamMap) => {
      const action = {
        mode: parmaMap.get('mode') as SessionMode,
        oobCode: parmaMap.get('oobCode'),
        lang: parmaMap.get('lang'),
      };

      switch (action.mode) {
        case 'verifyEmail': {
          this.router.navigate(['/session'], {
            queryParams: action,
          });
          break;
        }
        case 'resetPassword': {
          this.router.navigate(['/session'], {
            queryParams: action,
          });
          break;
        }
      }
    });
  }
}
