import { Component } from '@angular/core';
@Component({
  selector: 'app-default.layout',
  templateUrl: './default.layout.component.html',
  styleUrls: ['./default.layout.component.scss'],
})
export class DefaultLayoutComponent {
  public showNewTopNav = false;

  constructor() {
    const currentBasePath = document.location.pathname.split('/')[1];
    if (currentBasePath === 'institutes') {
      this.showNewTopNav = true;
    } else {
      this.showNewTopNav = false;
    }
  }
}
