import { Model } from './generic-model';
import { FirebaseDeserializable } from './FirebaseDeserializable';

export class UserDTO extends Model {
  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public full_name?: string;
  public entityType?: string;
  public entityId?: string;
}

export class User extends UserDTO implements FirebaseDeserializable<UserDTO> {
  deserialize(input: UserDTO): this {
    Object.assign(this, input);
    return this;
  }

  constructor(input: any = {}) {
    super(input);
    return this.deserialize(input);
  }

  toJSON() {
    return Object.assign({}, this);
  }

  get fullName() {
    if (!this.firstName && !this.lastName) {
      return '-';
    }

    return `${!!this.firstName ? this.firstName : ''} ${!!this.lastName ? this.lastName : ''
      }`;
  }
}
