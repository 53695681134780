import { Component, Output, EventEmitter } from '@angular/core';
import { SessionMode } from '../session.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
})
export class ForgetPasswordComponent {
  @Output() switchMode: EventEmitter<SessionMode> =
    new EventEmitter<SessionMode>();

  forgotPassword_form: FormGroup;
  wait_for_api = false;
  isEmailSent = false;
  errorMessage = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private translate: TranslateService
  ) {
    this.initLoginForm();
  }

  initLoginForm() {
    this.forgotPassword_form = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
    });
  }

  async submit() {
    const lang = this.translate.currentLang;
    if (!this.forgotPassword_form.valid || this.wait_for_api) {
      return;
    }
    try {
      this.wait_for_api = true;
      const email = this.forgotPassword_form.value.email;
      await firstValueFrom(this.auth.forgotPassword(email, lang));
      this.isEmailSent = true;
      this.errorMessage = false;
    } catch (e) {
      this.wait_for_api = false;
      this.errorMessage = true;
    }
  }
}
