<footer [class.dark-theme]="true" class="new-layout footer">
  <section class="app-container">
    <div class="footer__content">
      <div>
        <div class="footer__content__logo">
          <img
            src="/assets/images/susan-logo-footer.svg"
            width="135"
            height="56"
            alt="susan-logo"
          />
        </div>
        <ng-container *ngIf="this.isSuccess; else subForm">
          <div class="text-base"><strong>Successfully subscribed!</strong></div>
        </ng-container>
        <ng-template #subForm>
          <form [formGroup]="newsletterForm">
            <div class="footer__content__news-letter">
              <div class="footer__content__news-letter__input-elem">
                <img
                  src="/assets/images/mail-open.svg"
                  alt="mail-open.svg"
                /><input
                  type="email"
                  formControlName="email"
                  placeholder="Subscribe to newsletter"
                  maxlength="40"
                />
                <ng-container *ngIf="!this.isValid">
                  <div
                    class="footer__content__news-letter__input-elem__validator"
                  >
                    *Please provide a valid email
                  </div>
                </ng-container>
                <ng-container *ngIf="this.isSomethingWrong">
                  <div class="footer__content__news-letter__input-elem__error">
                    Something went wrong. We are investigating the issue.
                  </div>
                </ng-container>
              </div>
              <ng-container *ngIf="this.isLoading; else showText"
                ><mat-spinner
                  class="footer__content__news-letter__input-elem__spinner"
                ></mat-spinner>
              </ng-container>
              <ng-template #showText>
                <button
                  mat-flat-button
                  color="primary"
                  [disabled]="this.isSuccess || this.isSomethingWrong"
                  (click)="subscribeNewsletter()"
                >
                  <strong>SUBSCRIBE</strong>
                </button>
              </ng-template>
            </div>
          </form>
        </ng-template>
        <div class="text-base footer__content__sub-description">
         
          By subscribing you will receive by mail news of <br /> the different Data Mangrove projects.

        </div>
        <div class="footer__content__social-icons">
          <img src="/assets/images/facebook.svg" alt="facebook" />
          <img src="/assets/images/twitter.svg" alt="twiter" />
          <img src="/assets/images/linked-in.svg" alt="linked-in" />
          <img src="/assets/images/instagram.svg" alt="instagram" />
        </div>
      </div>
      <div class="footer__content__navigation">
        <div class="footer__content__navigation__content-width">
          <div><strong>Navigation</strong></div>
          <!-- <div><a [routerLink]="''">Projects</a></div> -->
          <div><a [routerLink]="'/team'">Our team</a></div>
          <div><a [routerLink]="'/about'">About Us</a></div>
          <!-- <div><a [routerLink]="'/news'">News</a></div> -->
        </div>
        <div class="footer__content__navigation__content-width">
          <div><strong>Others</strong></div>
          <div><a [routerLink]="'/privacy-policy'">Privacy policy</a></div>
          <div><a [routerLink]="'/terms'">Terms & Conditions</a></div>
          <div><a [routerLink]="''">Sitemap</a></div>
        </div>
      </div>
    </div>
    <div class="text-base footer__copyright">
      © 2024 Data Mangrove. All Right Reserved.
    </div>
  </section>
</footer>
