import { Component, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import {
  OpenDataToken,
  OpenDataTokenStatus,
} from 'src/app/models/open-data-token.model';
import { OpenDataTokenService } from 'src/app/services/open-data-token.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin-request-access',
  templateUrl: './admin-request-access.component.html',
  styleUrls: ['./admin-request-access.component.scss'],
})
export class AdminRequestAccessComponent {
  @Input() title: string;
  private _data: OpenDataToken[];
  isLoading = false;

  columns = [
    {
      key: 'displayCreatedAt',
      header: 'Created',
    },
    { key: 'displayName', header: 'User' },
    { key: 'displayEmail', header: 'Email' },
    { key: 'displayTitleAdmin', header: 'Project' },
    {
      key: 'displayStatus',
      header: 'Status',
    },
    { key: 'lastColumnKey', header: '', id: 'id', isLoading: 'displayStatus' },
  ];

  dataSource = new MatTableDataSource<OpenDataToken>(this.data);

  @Input()
  set data(value: OpenDataToken[]) {
    this._data = value;
    this.dataSource.data = value;
  }

  get data(): OpenDataToken[] {
    return this._data;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private openDataTokenService: OpenDataTokenService,
    private snackBar: MatSnackBar
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getDisplayedColumns(): string[] {
    return this.columns.map((column) => column.key);
  }

  async handleClick(choice: OpenDataTokenStatus, data: any) {
    this.isLoading = true;
    const { ref, ...updatedData } = data;
    const updatedStatus = { ...updatedData, status: choice };
    try {
      await this.openDataTokenService.update(data.id, updatedStatus);
      this.snackBar.open('Success', 'OK');
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An error has occured, please try again or contact the support',
        'OK'
      );
    }
    this.isLoading = false;
  }
}
