import { FormGroup } from '@angular/forms';

export function passwordValidator(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (
      matchingControl.errors &&
      !matchingControl.errors['passwordValidator']
    ) {
      return null;
    }
    if (control.value !== matchingControl.value) {
      if (!!control.value && !!matchingControl.value) {
        matchingControl.setErrors({ passwordValidator: true });
      }
      return { passwordValidator: true };
    } else {
      matchingControl.setErrors(null);
      return null;
    }
  };
}
