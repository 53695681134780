<div class="full-width-title" *ngIf="definition">
  <app-dots
    [color]="'#548c2f'"
    [reverse]="true"
    class="full-width-title__prefix"
  ></app-dots>
  <div class="full-width-title__content">
    <div class="full-width-title__content__line1">
      {{ definition.line1 | translate }}
    </div>
    <div class="full-width-title__content__line2">
      {{ definition.line2 | translate }}
    </div>
  </div>
  <app-dots
    [color]="'#548c2f'"
    [reverse]="true"
    class="full-width-title__suffix"
  ></app-dots>
</div>
