<div class="contributor-card">
  <div class="contributor-card__layer" (click)="exit.emit()"></div>

  <div class="contributor-card__body">
    <img
      class="contributor-card__body__exit"
      (click)="exit.emit()"
      src="../../../../assets/images/close.png"
      alt="close"
    />
    <div class="contributor-card__body__preview">
      <div
        class="contributor-card__body__preview__selfie"
        [ngStyle]="{ backgroundImage: 'url(\'' + contributor.picture + '\')' }"
      ></div>
      <div class="contributor-card__body__preview__socials">
        <a
          [href]="contributor.linkedIn"
          target="_blank"
          class="contributor-card__body__preview__socials__item"
          *ngIf="contributor.linkedIn"
        >
          <img
            src="../../../../assets/images/linkedin.svg"
            alt=""
            class="contributor-card__body__preview__socials__item__icon"
          />
          LinkedIn
        </a>
      </div>
    </div>
    <div class="contributor-card__body__content">
      <div class="contributor-card__body__content__name">
        {{ contributor.firstName }} {{ contributor.lastName }}
      </div>

      <div
        class="contributor-card__body__content__titles"
        [innerText]="contributor.displayTitle"
      ></div>

      <div
        class="contributor-card__body__content__biography"
        [innerHtml]="contributor.displayBiography"
      ></div>
    </div>
  </div>
</div>
