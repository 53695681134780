import { Injectable } from '@angular/core';
import { CollectionGenericLibService } from './collection-generic-lib.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Universities, UniversitiesDTO } from '../models/universities.model';

@Injectable({
  providedIn: 'root',
})
export class UniversitiesService extends CollectionGenericLibService<
  Universities,
  UniversitiesDTO
> {
  COLLECTION_NAME = 'universities';
  projectUniversity: Universities[];

  constructor(public override firestore: AngularFirestore) {
    super(firestore);
    this.c = Universities;
    this.collectionName = this.COLLECTION_NAME;
  }
}
