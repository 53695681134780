import { Injectable } from '@angular/core';
import { CollectionGenericLibService } from './collection-generic-lib.service';
import { Institute, InstituteDTO } from '../models/institute.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class InstituteService extends CollectionGenericLibService<
  Institute,
  InstituteDTO
> {
  COLLECTION_NAME = 'institutes';

  constructor(public override firestore: AngularFirestore) {
    super(firestore);
    this.c = Institute;
    this.collectionName = this.COLLECTION_NAME;
  }
}
