export const environment = {
  useEmulators: false,
  default_language: 'en',
  firebase: {
    apiKey: 'AIzaSyCHuwYV6RV_xk4DeTzyZmSACadYTbhXmwg',
    authDomain: 'susanhub-qa.firebaseapp.com',
    projectId: 'susanhub-qa',
    storageBucket: 'susanhub-qa.appspot.com',
    messagingSenderId: '652189019524',
    appId: '1:652189019524:web:08121a8ec1c22d5ca0c1d1',
    measurementId: 'G-HT45QLYS46',
  },
  googleMapsApiKey: 'AIzaSyAd7mkiEI9-WIcQ-GM7yXURZZItyEFQro4',
  showTeamPage: true,
  host: 'https://susanhub.com'
};
