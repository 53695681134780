import { Injectable } from '@angular/core';
import { CollectionGenericLibService } from './collection-generic-lib.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ResearcherDTO, Researchers } from '../models/researcher.model';

@Injectable({
  providedIn: 'root',
})
export class ResearchersService extends CollectionGenericLibService<
  Researchers,
  ResearcherDTO
> {
  COLLECTION_NAME = 'researchers';

  constructor(public override firestore: AngularFirestore) {
    super(firestore);
    this.c = Researchers;
    this.collectionName = this.COLLECTION_NAME;
  }
}
