<div class="default-layout">
  <div class="default-layout__content">
    <ng-container *ngIf="true; else showDefauleTopNav">
      <app-new-top-nav class="fixed z-10 w-full bg-white shadow-sm"></app-new-top-nav>
    </ng-container>
    <ng-template #showDefauleTopNav><app-top-nav
        class="default-layout__content__top-nav fixed z-10 w-full bg-white shadow-sm"></app-top-nav></ng-template>
    <div class="pt-16">
      <router-outlet></router-outlet>
    </div>
  </div>
  <ng-container>
    <app-new-footer></app-new-footer>
  </ng-container>
</div>