import { Model } from './generic-model';
import { FirebaseDeserializable } from './FirebaseDeserializable';
import { InternationalizedService } from '../services/internationalized.service';

export type InstituteTypes =
  | 'Student-led initiative'
  | 'Research Center'
  | 'Research Institute'
  | 'Teaching-led initiative'
  | 'Granting/Funding agency'
  | 'Research Incubator'
  | 'Other';
export class InstituteDTO extends Model {
  public name: string;
  public country: string;
  public universities: string[];
  public latitude: number;
  public longitude: number;
  public categories: string[];
  public subcategories: string[];
  public logo?: string;
  public website?: string;
  public size?: string;
  public languages?: string[];
  public blurb?: string;
  public description?: string;
  public fields?: string[];
  public sdgs?: string[];
  public uuid?: string;
  public email: string;
  public faculty?: string;
  public type: InstituteTypes;
}

export class Institute
  extends InstituteDTO
  implements FirebaseDeserializable<InstituteDTO>
{
  deserialize(input: InstituteDTO): this {
    Object.assign(this, input);
    return this;
  }

  constructor(input: any = {}) {
    super(input);
    return this.deserialize(input);
  }

  toJSON() {
    return Object.assign({}, this);
  }

  get displayName() {
    return this.name;
  }

  get displayCountry() {
    return this.country;
  }

  get displayUniversity() {
    return this.universities;
  }

  get displayFields() {
    return `${this.fields.join(', ')}`;
  }

  get displaySDGs() {
    return `${this.sdgs.join(', ')}`;
  }

  get displayBlurb() {
    return this.blurb;
  }

  get displayDescription() {
    return this.description.split('\\n').join('<br/>');
  }

  get displayLocation() {
    return [this.latitude, this.longitude];
  }

  get displayLanguages() {
    return `${this.languages.join(', ')}`;
  }

  get displayCategories() {
    return `${this.categories.join(', ')}`;
  }

  get displaySubcategories() {
    return `${this.subcategories.join(', ')}`;
  }

  get mainCategory() {
    return this.categories[0];
  }

  get mainSubcategory() {
    return this.subcategories[0];
  }

  get displayType() {
    return this.type;
  }
}
