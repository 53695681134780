<form class="reset-password" [formGroup]="resetPasswordForm">
  <div class="reset-password__field">
    <mat-form-field class="reset-password__field__item" appearance="outline">
      <mat-label>{{
        "reset-password.item-password-label" | translate
      }}</mat-label>
      <mat-icon matPrefix>key</mat-icon>
      <input matInput type="password" [formControlName]="'password'" />
      <mat-error></mat-error>
    </mat-form-field>
    <mat-form-field class="reset-password__field__item" appearance="outline">
      <mat-label>{{
        "reset-password.item-email-confirmation-label" | translate
      }}</mat-label>
      <mat-icon matPrefix>key</mat-icon>
      <input
        matInput
        type="password"
        [formControlName]="'passwordConfirmation'"
      />
      <mat-error></mat-error>
    </mat-form-field>
  </div>

  <div class="reset-password__action">
    <button-loading
      color="primary"
      [loading]="waitForApi"
      [disabled]="resetPasswordForm.invalid"
      (click)="submit()"
    >
      {{ "reset-password.button-forget-password" | translate }}
    </button-loading>
  </div>
</form>
