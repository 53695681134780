<form class="register" [formGroup]="register_form">
  <ng-container *ngIf="!registration_completed; else completed">
    <div class="register__field">
      <mat-form-field class="register__field__item" appearance="outline">
        <mat-label>{{ "register.first-name" | translate }}</mat-label>
        <mat-icon matPrefix>account_circle</mat-icon>
        <input matInput [formControlName]="'first_name'" />
        <mat-error></mat-error>
      </mat-form-field>
    </div>

    <div class="register__field">
      <mat-form-field class="register__field__item" appearance="outline">
        <mat-label>{{ "register.last-name" | translate }}</mat-label>
        <mat-icon matPrefix>account_circle</mat-icon>
        <input matInput [formControlName]="'last_name'" />
        <mat-error></mat-error>
      </mat-form-field>
    </div>

    <div class="register__field">
      <mat-form-field class="register__field__item" appearance="outline">
        <mat-label>{{ "register.item-email-label" | translate }}</mat-label>
        <mat-icon matPrefix>email</mat-icon>
        <input matInput [formControlName]="'email'" />
        <mat-error></mat-error>
      </mat-form-field>
    </div>

    <div class="register__field">
      <mat-form-field class="register__field__item" appearance="outline">
        <mat-label>{{ "register.item-password-label" | translate }}</mat-label>
        <mat-icon matPrefix>key</mat-icon>
        <input matInput [type]="'password'" [formControlName]="'password'" />
        <mat-error></mat-error>
      </mat-form-field>
    </div>

    <div class="register__field">
      <mat-form-field class="register__field__item" appearance="outline">
        <mat-label>{{
          "register.item-email-confirmation-label" | translate
        }}</mat-label>
        <mat-icon matPrefix>key</mat-icon>
        <input
          matInput
          [type]="'password'"
          [formControlName]="'confirm_password'"
        />
        <mat-error></mat-error>
      </mat-form-field>
    </div>

    <div class="register__field">
      <div class="register__field__radio">
        <label>{{ "register.prefered-language-label" | translate }}</label>
        <mat-radio-group [formControlName]="'prefered_language'">
          <mat-radio-button
            value="en"
            [color]="'primary'"
            [checked]="register_form.get('prefered_language').value === 'en'"
            >English</mat-radio-button
          >
          <mat-radio-button
            value="fr"
            [color]="'primary'"
            [checked]="register_form.get('prefered_language').value === 'fr'"
            >Français</mat-radio-button
          >
          <mat-radio-button
            value="es"
            [color]="'primary'"
            [checked]="register_form.get('prefered_language').value === 'es'"
            >Español</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </div>

    <div class="register__field">
      <div class="register__field__newsletter">
        <mat-checkbox [formControlName]="'newsletter'" [color]="'primary'">{{
          "register.newsletter-label" | translate
        }}</mat-checkbox>
      </div>
    </div>

    <div class="register__action">
      <a (click)="switchMode.emit('login')">{{
        "register.button-signin" | translate
      }}</a>

      <button-loading
        color="primary"
        [loading]="wait_for_api"
        [disabled]="register_form.invalid"
        (click)="submit()"
      >
        {{ "register.button-register" | translate }}
      </button-loading>
    </div>
  </ng-container>
  <ng-template #completed>
    <span class="register__completed">
      {{ "register.completed-message" | translate }}
      <a (click)="switchMode.emit('login')">{{
        "register.button-return-to-Login" | translate
      }}</a>
    </span>
  </ng-template>
</form>
