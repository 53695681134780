<!-- {{themeData |json}} -->

<div class="mr-1 rounded-full mb-1 bg-themeDefaultBg" [ngStyle]="{'background-color': themeData?.secondaryColor }">
  <div [ngClass]="classesData ? 'p-[2px]' : 'p-1'" class="flex items-center w-fit h-fit cursor-pointer">
    <div *ngIf="imageShow">
      <ng-container *ngIf="!themeData.logo; else showLogo">
        <img [ngClass]="classesData ? 'w-4 h-4' : 'w-6 h-6'" class="rounded-full" src="../../../assets/images/globe.svg" alt="Selected Icon">
      </ng-container>
      <ng-template #showLogo>
        <img [ngClass]="classesData ? 'w-4 h-4' : 'w-6 h-6'" class=" rounded-full" [src]="themeData?.logo" [alt]="themeData?.name">
      </ng-template>
    </div>
    <div *ngIf="!imageShow">
      <div class="h-6"></div>
    </div>


    <span [ngClass]="classesData ? 'text-xs' : 'text-sm '" class="text-[#24292E] pl-1 pr-2 normal-case">{{ themeData?.name }}</span>
  </div>
</div>