import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dots',
  templateUrl: './dots.component.html',
  styleUrls: ['./dots.component.scss'],
})
export class DotsComponent {
  @Input() color: string = 'white';
  @Input() reverse: boolean = false;

  getBackgroundEffect() {
    return `radial-gradient(
      circle at
      2px
      2px,

      ${this.color} 1px,
      #0000 2px
  )
  0 0 / 15px 15px`;
  }
}
