import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './services/auth.service';
import { User } from './models/userInfo.model';
import { CookieService } from 'ngx-cookie-service';


declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'mcgill-datahub';
  static isBrowser = new BehaviorSubject<boolean>(null);
  userAuthUser$: Observable<User> = this.authService.getUser();
  showCookieDialog = true; 

  constructor(@Inject(PLATFORM_ID) private platformId: any, public router: Router, private authService: AuthenticationService, private cookieService: CookieService) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));

    // Google analytics tracking
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.firebase.measurementId, { 'page_path': event.urlAfterRedirects });
      }
    })
  }


  ngOnInit() {
    // this.userAuthUser$.pipe(
    //   map((user: any) => {
    //     console.log("USER : ", user);
    //   })
    // ).subscribe();
    const consent = this.cookieService.get('cookieConsent');
    if (consent) {
      this.showCookieDialog = false; // Hide dialog if consent exists
    }
  }

  handleAcceptCookies(): void {
    // Logic for accepting cookies
    this.cookieService.set('cookieConsent', 'accepted', 365, '/'); // Save cookie consent
    this.showCookieDialog = false; // Hide dialog after accepting
  }

  handleCloseDialog(newCloseState: boolean): void {
    this.showCookieDialog = newCloseState; // Update dialog visibility
  }
}
