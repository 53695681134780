import { Model } from './generic-model';
import { FirebaseDeserializable } from './FirebaseDeserializable';
import { Timestamp } from 'firebase/firestore';
import { InternationalizedService } from '../services/internationalized.service';

export class NewsDTO extends Model {
  public title: {
    en: string;
    fr: string;
    es: string;
  };
  public description?: {
    en: string;
    fr: string;
    es: string;
  };
  public image: string;
  public link?: string;
  public source?: string;
  public project?: {
    name_en: string;
    name_es: string;
    name_fr: string;
    link: string;
  };
  public date?: Timestamp;
  public defaultLang?: string;
}

export class News extends NewsDTO implements FirebaseDeserializable<NewsDTO> {
  deserialize(input: NewsDTO): this {
    Object.assign(this, input);
    return this;
  }

  constructor(input: any = {}) {
    super(input);
    return this.deserialize(input);
  }

  toJSON() {
    return Object.assign({}, this);
  }

  get displayTitle() {
    return this.title[InternationalizedService.getLocale()];
  }

  get displayDescription() {
    return this.description?.[InternationalizedService.getLocale()];
  }

  get displayProjectName() {
    return this.project?.[`name_${InternationalizedService.getLocale()}`];
  }

  get displayDate() {
    const date = new Date(this.date?.toDate() || 0);
    return date.toLocaleDateString(InternationalizedService.getLocale(), {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
}
