import { Injectable } from '@angular/core';
import { CollectionGenericLibService } from './collection-generic-lib.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subject, firstValueFrom } from 'rxjs';
import { OpenDatas, OpenDatasDTO } from '../models/open-datas.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root',
})
export class OpenDatasService extends CollectionGenericLibService<
  OpenDatas,
  OpenDatasDTO
> {
  COLLECTION_NAME = 'open-datas-access';
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public override firestore: AngularFirestore,
    private fsFunction: AngularFireFunctions
  ) {
    super(firestore);
    this.c = OpenDatas;
    this.collectionName = this.COLLECTION_NAME;
  }

  destroySubscriptions() {
    this.destroyGenericSubscriptions();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  deleteOpenData(data): Promise<void> {
    return firstValueFrom(
      this.fsFunction.httpsCallable('deleteOpenDataAccess')(data)
    );
  }
}
