import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent {
  @Input() picture: string;
  @Input() description: string;
  @Output() exit: EventEmitter<void> = new EventEmitter<void>();
}
