<div class="email-verified">
  <div class="email-verified__content">
    <div class="email-verified__content__item">
      <ng-container *ngIf="mode === 'loading'">
        <mat-spinner></mat-spinner>
      </ng-container>

      <ng-container *ngIf="mode === 'success'">
        <span>{{ "email-verified.text-success" | translate }}</span>
      </ng-container>

      <ng-container *ngIf="mode === 'failed'">
        <span>{{ "email-verified.text-failed" | translate }}</span>
      </ng-container>
    </div>

    <button
      *ngIf="mode !== 'loading'"
      mat-flat-button
      color="primary"
      (click)="switchMode.emit('login')"
    >
      {{ "email-verified.button-signin" | translate }}
    </button>
  </div>
</div>
