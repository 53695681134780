import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Screenshot } from 'src/app/models/project-list.model';
import Timeout = NodeJS.Timeout;
import { AuthenticationService } from '../../services/authentication.service';
import { firstValueFrom } from 'rxjs';
import { OpenDataService } from '../../services/open-data.service';
import { FilterSet } from '../../services/collection-generic-lib.service';
import { ProjectOpenDataSectionDefinition } from './project-open-data-section/project-open-data-section.component';
import { OpenDataTokenService } from '../../services/open-data-token.service';
import { User } from '../../models/user.model';
import {
  OpenDataToken,
  OpenDataTokenStatus,
} from '../../models/open-data-token.model';
import { DateTime } from 'luxon';
import { ProjectsListService } from 'src/app/services/projects-list.service';
import { Project } from 'src/app/models/project-list.model';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizedService } from 'src/app/services/internationalized.service';
import { ContributorsService } from 'src/app/services/contributors.service';
import { Contributors } from 'src/app/models/contributors.model';
import { SponsorsService } from 'src/app/services/sponsors.service';
import { Sponsors } from 'src/app/models/sponsors.model';
import { UniversitiesService } from 'src/app/services/universities.service';
import { Universities } from 'src/app/models/universities.model';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit {
  isLoading = true;
  isProjectTokenLoading = false;
  project: Project;
  projectSponsors: Sponsors[];
  projectContributor: Contributors[];
  projectUniversity: Universities[];
  projectFireStore: Project;

  galleryIndex = 0;

  selectedScreenshot: Screenshot;
  selectedContributor: Contributors;
  selectedUniversity: Universities;

  galleryTimeout: Timeout;

  openDataAccess = false;

  openDataListDefinitions: ProjectOpenDataSectionDefinition[] = [];

  user_data: User;

  open_data_wait_for_api = false;

  request_status: OpenDataTokenStatus;

  request_access_step = 1;

  request_legal_check = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private auth: AuthenticationService,
    private openDataService: OpenDataService,
    private openDataTokenService: OpenDataTokenService,
    private projectsListService: ProjectsListService,
    private contributorsService: ContributorsService,
    public translate: TranslateService,
    private language: InternationalizedService,
    private sponsorsService: SponsorsService,
    private universitiesService: UniversitiesService
  ) {}

  async initRequestStatus(project_id: string, user_id: string) {
    const filter: FilterSet = {
      project_id: {
        comparator: '==',
        value: project_id,
      },
      user_id: {
        comparator: '==',
        value: user_id,
      },
      expire_at: {
        comparator: '>',
        value: DateTime.now().toJSDate(),
      },
    };

    const existing_token = await firstValueFrom(
      this.openDataTokenService.listData(filter)
    );

    if (!existing_token.length) {
      return;
    }

    this.request_status = existing_token[0].status;
  }

  async initOpenDataAccess(project_id: string) {
    this.user_data = await firstValueFrom(this.auth.currentProfile$);
    if (!this.user_data) {
      return;
    }

    this.openDataAccess =
      this.user_data?.project_open_data_access?.includes(project_id);

    if (!this.openDataAccess) {
      await this.initRequestStatus(project_id, this.user_data.id);
    }

    await this.initOpenDataList();
  }

  async initOpenDataList() {
    if (!this.project?.open_data_export_url) {
      return;
    }

    const request = {
      task_type: 'GetInfo',
      open_data_export_url: this.project.open_data_export_url,
    };

    this.open_data_wait_for_api = true;
    this.openDataListDefinitions = (
      await firstValueFrom(this.openDataService.manageOpenData(request))
    ).map((data) => {
      return {
        openData: data,
        project: this.project,
      };
    });
    this.open_data_wait_for_api = false;
  }

  ngOnInit() {
    //When we change the url of each project, send a request to firebase to get the document
    this.activatedRoute.params.subscribe(async (params) => {
      this.isLoading = true;
      //Get the project
      this.projectsListService
        .listData({ uuid: { comparator: '==', value: params['id'] } })
        .subscribe(async (projects) => {
          this.project = projects[0];
          //Get the contributor in that project
          this.contributorsService
            .listData({
              projectIds: {
                comparator: 'array-contains',
                value: this.project.id,
              },
            })
            .subscribe((contributors) => {
              this.projectContributor = contributors;
            });
          //Get the sponsors in that project
          this.sponsorsService
            .listData({
              projectIds: {
                comparator: 'array-contains',
                value: this.project.id,
              },
            })
            .subscribe((sponsors) => {
              this.projectSponsors = sponsors;
              this.isLoading = false;
            });
          //Get the universities in that project
          this.universitiesService
            .listData({
              projectIds: {
                comparator: 'array-contains',
                value: this.project.id,
              },
            })
            .subscribe((universities) => {
              this.projectUniversity = universities;
              this.isLoading = false;
            });
          await this.initOpenDataAccess(params['id']);
        });
    });
    this.iterateGalleryIndex();
  }

  iterateGalleryIndex() {
    this.galleryTimeout = setTimeout(() => {
      this.galleryIndex += 1;

      if (this.galleryIndex === this.project.screenshots.length) {
        this.galleryIndex = 0;
      }

      this.iterateGalleryIndex();
    }, 5000);
  }

  selectScreenshot(screenshot) {
    this.selectedScreenshot = screenshot;
  }

  setGalleryIndex(newIndex: number) {
    if (newIndex < 0) {
      this.galleryIndex = this.project.screenshots.length - 1;
    } else if (newIndex >= this.project.screenshots.length) {
      this.galleryIndex = 0;
    } else {
      this.galleryIndex = newIndex;
    }

    // Reset timeout
    clearTimeout(this.galleryTimeout);
    this.iterateGalleryIndex();
  }

  async requestOpenDataAccess() {
    this.isProjectTokenLoading = true;
    if (!this.request_legal_check) {
      return;
    }
    await this.openDataTokenService.createOpenDataToken(this.project.uuid);
    this.isProjectTokenLoading = false;
    this.request_status = 'pending';
  }

  selectContributor(contributor: Contributors) {
    this.selectedContributor = contributor;
  }

  hasStudentTeamMember(): boolean {
    return this.projectContributor?.some(
      (contributor) => !contributor.isFacultyMember
    );
  }

  hasFacultyTeamMember(): boolean {
    return this.projectContributor.some(
      (contributor) => contributor.isFacultyMember
    );
  }

  selectUniversity(university: Universities) {
    this.selectedUniversity = university;
  }

  openUniversityWebsite(university: Universities) {
    if (university && university.website) {
      window.open(university.website, '_blank');
    }
  }
}
