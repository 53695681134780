<div class="admin">
  <div class="admin__hero">
    <div class="admin__hero__background">
      <div class="admin__hero__background__layer"></div>
      <div class="admin__hero__background__fade-layer"></div>
      <video
        class="admin__hero__background__video"
        [autoplay]="true"
        [muted]="true"
        [loop]="true"
        [playbackRate]="0.7"
      >
        <source src="../../../assets/videos/homepage.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="admin__hero__title">
      <img
        class="admin__hero__title__image"
        src="../../../assets/images/shape_title_homepage.svg"
      />
      <div class="admin__hero__title__text">Access Management</div>
    </div>
    <app-dots class="admin__hero__dots"></app-dots>
  </div>
  <div class="admin__container-table">
    <app-admin-request-access
      [title]="'Request Access'"
      [data]="dataTableRequest"
    ></app-admin-request-access>
    <app-admin-current-access
      [title]="'Current Access'"
      [data]="dataTableCurrent"
    ></app-admin-current-access>
  </div>
</div>
