//TODO: This is a temporary implementation. Implement these functionalities with the existing architecture once refactoring starts.

import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/compat/firestore';
import { doc, OrderByDirection, WhereFilterOp } from 'firebase/firestore';
import { catchError, from, map, merge, of, shareReplay, Subject, takeUntil } from 'rxjs';
import { COLLECTION_NAMES, PAGINATION_PER_PAGE_DATA } from '../constants/constants';
import { isResearcher } from '../utils/utils';

export type FilterType = {
  field: string;
  comparator: WhereFilterOp;
  value: string | string[] | boolean | number | number[];
};

type OrderType = {
  field: string;
  condition: OrderByDirection;
};

@Injectable({
  providedIn: 'root',
})
export class FirebaseCollectionService {
  querySubscription$ = new Subject();
  constructor(private firestore: AngularFirestore) { }

  public queryCollection(
    collectionName: string,
    filters?: FilterType[],
    orderBy?: OrderType[],
    limit = 30,
    startAfter?: any,
    startAt?: number
  ) {
    const filterFunction = (ref: Query): Query => {
      let query: Query = null;
      if (filters) {
        query = this.getFilters(query, ref, filters);
      }

      if (orderBy) {
        query = this.orderBy(query, ref, orderBy)
      }

      if (orderBy && startAfter) {
        query = this.startAfter(query, ref, startAfter);
      }

      if (startAt) {
        query = this.startAt(query, ref, startAt);
      }

      if (limit) {
        query = this.limit(query, ref, limit);
      }

      return query;
    };

    if (filters || orderBy || limit) {
      return this.firestore
        .collection(collectionName, filterFunction)
        .snapshotChanges();
    } else {
      return this.firestore.collection(collectionName).snapshotChanges();
    }
  }

  public queryCollectionSnapshot(
    collectionName: string,
    filters?: FilterType[],
    orderBy?: OrderType[],
    limit?: number,
    startAfter?: any,
    startAt?: number,
    requireRef?: boolean
  ) {
    const filterFunction = (ref: Query): Query => {
      let query: Query = null;
      if (filters?.length) {
        query = this.getFilters(query, ref, filters);
      }

      if (orderBy?.length) {
        query = this.orderBy(query, ref, orderBy)
      }

      if (orderBy?.length && startAfter) {
        query = this.startAfter(query, ref, startAfter);
      }

      if (orderBy?.length && startAt) {
        query = this.startAt(query, ref, startAt, limit);
      }

      if (limit) {
        query = this.limit(query, ref, limit);
      } else {
        query = this.limit(query, ref, 30);
      }

      return query;
    };


    if (filters?.length || orderBy?.length || limit) {
      return this.firestore
        .collection(collectionName, filterFunction)
        .snapshotChanges().pipe(
          // shareReplay(1),
          takeUntil(this.querySubscription$),
          map(snapshots => {
            const result = [];
            snapshots.forEach(snapshot => {
              result.push({
                ...snapshot.payload.doc.data() as any,
                id: snapshot.payload.doc.id,
                ...requireRef ? { ref: snapshot.payload.doc } : {}
              });
            });
            return result;
          }),
          catchError(e => {
            console.error(of(e));
            return of(e);
          })
        );
    } else {
      return this.firestore.collection(collectionName).snapshotChanges().pipe(
        // shareReplay(1),
        takeUntil(this.querySubscription$),
        map(snapshots => {
          const result = [];
          snapshots.forEach(snapshot => {
            result.push({
              ...snapshot.payload.doc.data() as object,
              id: snapshot.payload.doc.id,
              ref: snapshot.payload.doc
            });
          });
          return result;
        }),
        catchError(e => {
          console.error(of(e));
          return of(e);
        })
      );;
    }
  }

  startAt(query, ref, startAt, limit?) {
    const startFrom = startAt;
    const endTo = startAt + PAGINATION_PER_PAGE_DATA - 1; // 1 + 11 (max page data - 1)

    if (query) {
      if (limit) {
        query = query.startAt(startFrom);
      } else {
        query = query.startAt(startFrom).endAt(endTo);
      }
    } else {
      if (limit) {
        query = ref.startAt(startFrom)
      } else {
        query = ref.startAt(startFrom).endAt(endTo);
      }
    }

    return query;
  }

  startAfter(query, ref, startAfter) {
    if (query) {
      query = query.startAfter(startAfter);
    } else {
      query = ref.startAfter(startAfter);
    }

    return query;
  }

  limit(query, ref, limit) {
    if (query) {
      query = query.limit(limit);
    } else {
      query = ref.limit(limit);
    }
    return query;
  }

  orderBy(query, ref, orderBy) {
    for (const order of orderBy) {
      if (query) {
        query = query.orderBy(order.field, order.condition);
      } else {
        query = ref.orderBy(order.field, order.condition);
      }
    }
    return query;
  }

  getFilters(query, ref, filters) {
    for (const filterData of filters) {
      if (query) {
        query = query.where(
          filterData.field,
          filterData.comparator,
          filterData.value
        );
      } else {
        query = ref.where(
          filterData.field,
          filterData.comparator,
          filterData.value
        );
      }
    }

    return query;
  }

  getSearchQueryFilter(searchTerm) {
    return {
      field: 'keywords',
      comparator: 'array-contains',
      value: searchTerm,
    };
  }

  getDateRangeQueryFilter(from, to, attribute) {
    const queries = [];
    queries.push(
      {
        field: attribute,
        comparator: '>=',
        value: from,
      }
    );
    queries.push(
      {
        field: attribute,
        comparator: '<=',
        value: to,
      }
    );
    return queries;
  }

  getFilterQueryFilter(filterTerms: string[]) {
    const queries = [];
    queries.push(
      {
        field: 'keywords',
        comparator: 'array-contains-any',
        value: [...filterTerms].slice(0, 30),
      }
    );
    return queries;
  }

  public getDocument(collectionName: string, documentId: string) {
    return this.firestore
      .collection(collectionName)
      .doc(documentId)
      .snapshotChanges();
  }

  public getAffiliation(entity) {
    return this.queryCollection(COLLECTION_NAMES.institutes, [
      {
        field: 'id',
        comparator: 'in',
        value: entity.InstituteIds.slice(0, 30),
      },
    ]);

  }

  getEmailFieldClause(ref, attribute, attributeValue, entityType) {
    if (!entityType || isResearcher(null, entityType)) {
      return ref.where(attribute, "==", attributeValue);
    } else {
      return ref.where(`contact.${attribute}`, "==", attributeValue);
    }
  }

  public getDocumentByAtrribute(collectionName: string, attribute: string, attributeValue: string, entityType?: string) {
    return this.firestore
      .collection(collectionName, ref => this.getEmailFieldClause(ref, attribute, attributeValue, entityType))
      // .doc(documentId)
      .snapshotChanges()
      .pipe(
        map(snapshots => {
          const result = [];
          snapshots.forEach(snapshot => {
            result.push({
              ...snapshot.payload.doc.data() as object,
              id: snapshot.payload.doc.id
            });
          });
          return result;
        }),
        catchError(e => {
          console.error(of(e));
          return of(e);
        })
      )
  }

  public updateDocument(collectionName, documentId, payload) {
    return from(this.firestore
      .collection(collectionName)
      .doc(documentId).update(payload));
  }

  public addDocument(collectionName, payload) {
    return from(this.firestore
      .collection(collectionName)
      .add(payload));
  }

  public deleteDocument(collectionName, docId) {
    return from(this.firestore
      .collection(collectionName).doc(docId).delete()
    );
  }
}
