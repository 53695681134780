import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root',
})
export class OpenDataService {
  constructor(private fsFunction: AngularFireFunctions) {}

  manageOpenData(request: any) {
    return this.fsFunction.httpsCallable('manageProjectOpenData')(request);
  }
}
