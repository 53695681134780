import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Editor, NgxEditorModule, schema, Toolbar } from 'ngx-editor';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-markdown-editor',
  standalone: true,
  imports: [CommonModule, NgxEditorModule, FormsModule, ReactiveFormsModule],
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss']
})
export class MarkdownEditorComponent {

  formControl: FormControl<any>;
  @Input() markDown;
  @Input() showMarkDownHeader: boolean;
  @Input() placeHolder: string;
  @Input() set control(formControl: FormControl) {
    this.formControl = formControl;
    this.content = formControl.value || '';
  }
  renderData: any;
  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic', 'link', 'code', 'strike', 'ordered_list', 'bullet_list'],
  ];

  content: Record<string, any>;

  ngOnInit() {
    this.editor = new Editor({
      schema,
      history: true,
      keyboardShortcuts: true,
      inputRules: true,
      attributes: { enterkeyhint: 'enter' },
      features: {
        linkOnPaste: true,
        resizeImage: true,
      },
    });
  }

  editorChange(event: any) {
    if (this.renderData) {
      try {
        this.formControl.setValue(this.content);
      } catch (error) {
        console.log(error);
      }
    }

    this.renderData = true;
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
