import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionMode } from '../session.component';
import { passwordValidator } from '../../shared/helpers/passwordValidator';
import { AuthenticationService } from '../../../services/authentication.service';
import { User } from '../../../models/user.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  @Output() switchMode: EventEmitter<SessionMode> =
    new EventEmitter<SessionMode>();

  register_form: FormGroup;
  wait_for_api = false;

  registration_completed = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthenticationService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.initRegisterForm();
  }

  ngOnInit() {}

  initRegisterForm() {
    this.register_form = this.fb.group(
      {
        first_name: [null, [Validators.required]],
        last_name: [null, [Validators.required]],
        email: [null, [Validators.email, Validators.required]],
        password: [null, [Validators.required]],
        confirm_password: [null, [Validators.required]],
        prefered_language: [this.translate.currentLang, [Validators.required]],
        newsletter: [false],
      },
      {
        validators: passwordValidator('password', 'confirm_password'),
      }
    );
  }

  async submit() {
    if (this.register_form.invalid) {
      return;
    }
    this.wait_for_api = true;

    try {
      const user_data = new User({
        first_name: this.register_form.value.first_name,
        last_name: this.register_form.value.last_name,
        email: this.register_form.value.email,
        language: this.register_form.value.prefered_language,
        newsletter: this.register_form.value.newsletter,
      });

      await this.auth.register(
        this.register_form.value.email,
        this.register_form.value.password,
        user_data
      );
      this.wait_for_api = false;
      this.registration_completed = true;

      setTimeout(() => {
        this.switchMode.emit('login');
      }, 10000);
    } catch (e) {
      console.error(e);
      this.snackBar.open(this.auth.generateAuthErrorMessage(e), 'OK');
      this.wait_for_api = false;
    }
  }
}
