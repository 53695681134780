import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { DefaultLayoutComponent } from './layouts/default.layout/default.layout.component';
import { TopNavComponent } from './components/shared/top-nav/top-nav.component';
import { ProjectComponent } from './components/project/project.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { environment } from '../environments/environment';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import { AngularFireModule } from '@angular/fire/compat';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DotsComponent } from './components/shared/dots/dots.component';
import { FullWidthTitleComponent } from './components/shared/full-width-title/full-width-title.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { HomepageProjectListComponent } from './components/homepage/homepage-project-list/homepage-project-list.component';
import { PartnersSectionComponent } from './components/shared/partners-section/partners-section.component';
import { BoardSectionComponent } from './components/shared/board-section/board-section.component';
import { StudentTeamSectionComponent } from './components/shared/student-team-section/student-team-section.component';
import { ProjectSectionComponent } from './components/project/project-section/project-section.component';
import { BiographyModaleComponent } from './components/shared/biography-modale/biography-modale.component';
import { ContributorCardComponent } from './components/shared/contributor-card/contributor-card.component';
import { ImagePreviewComponent } from './components/shared/image-preview/image-preview.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SessionComponent } from './components/session/session.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { ButtonLoadingComponent } from './components/shared/button-loading/button-loading.component';
import { MatButtonModule } from '@angular/material/button';
import { LoginComponent } from './components/session/login/login.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { RegisterComponent } from './components/session/register/register.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FirebaseEmailActionComponent } from './components/session/firebase-email-action/firebase-email-action.component';
import { EmailVerifiedComponent } from './components/session/email-verified/email-verified.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProjectOpenDataSectionComponent } from './components/project/project-open-data-section/project-open-data-section.component';
import { OpenDataAccessComponent } from './components/open-data-access/open-data-access.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatMenuModule } from '@angular/material/menu';
import { ForgetPasswordComponent } from './components/session/forget-password/forget-password.component';
import { ResetPasswordComponent } from './components/session/reset-password/reset-password.component';
import { ConfirmationResetComponent } from './components/session/confirmation-reset/confirmation-reset.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminRequestAccessComponent } from './components/admin/admin-request-access/admin-request-access.component';
import { AdminCurrentAccessComponent } from './components/admin/admin-current-access/admin-current-access.component';
import { ErrorForbiddenComponent } from './components/shared/error-forbidden/error-forbidden.component';
import { NewsCardComponent } from './components/shared/news-card/news-card.component';
import { NewsComponent } from './components/news/news.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { SustainabilityDirectoryComponent } from './components/sustainability-directory/sustainability-directory.component';
import { InstitutePageComponent } from './components/institute-page/institute-page.component';
import { AgmCoreModule } from '@agm/core';
import { InstitutePageSectionComponent } from './components/institute-page/institute-page-section/institute-page-section.component';
import { AdvisorSectionComponent } from './components/shared/advisors-section/advisors-section.component';
import { NewTopNavComponent } from './components/shared/new-top-nav/new-top-nav.component';
import { NewFooterComponent } from './components/shared/new-footer/new-footer.component';
import { MatChipsModule } from '@angular/material/chips';
import { ShThemeItemComponent } from './components/sh-theme-item/sh-theme-item.component';
import { CookiesModelComponent } from './components/cookies-model/cookies-model.component';
import { CookieService } from 'ngx-cookie-service';
import { MarkdownEditorComponent } from './components/markdown-editor/markdown-editor.component';
import { MarkdownComponent } from './components/markdown/markdown.component';
import { NgxEditorModule } from 'ngx-editor';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    DefaultLayoutComponent,
    TopNavComponent,
    NewTopNavComponent,
    ProjectComponent,
    DotsComponent,
    FullWidthTitleComponent,
    HomepageProjectListComponent,
    PartnersSectionComponent,
    BoardSectionComponent,
    StudentTeamSectionComponent,
    AdvisorSectionComponent,
    SustainabilityDirectoryComponent,
    ProjectSectionComponent,
    BiographyModaleComponent,
    ContributorCardComponent,
    ImagePreviewComponent,
    AuthLayoutComponent,
    SessionComponent,
    LoginComponent,
    RegisterComponent,
    ButtonLoadingComponent,
    FirebaseEmailActionComponent,
    EmailVerifiedComponent,
    ProjectOpenDataSectionComponent,
    OpenDataAccessComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    ConfirmationResetComponent,
    AdminComponent,
    AdminRequestAccessComponent,
    AdminCurrentAccessComponent,
    ErrorForbiddenComponent,
    NewsCardComponent,
    NewsComponent,
    InstitutePageComponent,
    InstitutePageSectionComponent,
    NewFooterComponent
  ],
  imports: [
    NgxEditorModule.forRoot({
      locals: {
        insert: 'Save',
        url: 'URL (Eg.https://susanhub.com)'
      },
    }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    MatButtonModule,
    MatPaginatorModule,
    MatTableModule,
    MatRadioModule,
    MatMenuModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    AngularFireModule,
    AngularFireModule.initializeApp(environment.firebase),
    ReactiveFormsModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    FormsModule,
    CookiesModelComponent,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
    }),
    ShThemeItemComponent,
    MarkdownEditorComponent,
    MarkdownComponent
  ],
  providers: [
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.useEmulators
        ? ['http://localhost:9099', 9099]
        : undefined,
    },
    {
      provide: USE_DATABASE_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 9000] : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 8080] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 5001] : undefined,
    },
    {
      provide: USE_STORAGE_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 9199] : undefined,
    },
    CookieService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
