import { Component, Input } from '@angular/core';
import { OpenData } from '../../../models/open-data.model';
import { OpenDataService } from '../../../services/open-data.service';
import { User } from '../../../models/user.model';
import { firstValueFrom } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Project } from 'src/app/models/project-list.model';

export interface ProjectOpenDataSectionDefinition {
  openData: OpenData;
  project: Project;
}

@Component({
  selector: 'app-project-open-data-section',
  templateUrl: './project-open-data-section.component.html',
  styleUrls: ['./project-open-data-section.component.scss'],
})
export class ProjectOpenDataSectionComponent {
  @Input() definition: ProjectOpenDataSectionDefinition | undefined;

  user_data: User;

  formatMessage = {};

  confirm_message;

  constructor(
    private openDataService: OpenDataService,
    private auth: AuthenticationService
  ) {
    this.initUserData();
  }

  async initUserData() {
    this.user_data = await firstValueFrom(this.auth.currentProfile$);
  }

  async generateDownloadLink(format: string) {
    if (!!this.formatMessage[format]) {
      return;
    }

    try {
      this.formatMessage[format] = 'loading';
      const request = {
        task_type: 'PushInfo',
        open_data_export_url: this.definition.project.open_data_export_url,
        uuid: this.definition.openData.uuid,
        customer_email: this.user_data.email,
        format_type: format.toLowerCase(),
      };

      await firstValueFrom(this.openDataService.manageOpenData(request));
      this.formatMessage[format] = `Sent`;
      this.confirm_message = _(
        'project-open-data-section.confirm-message-format-message-sent'
      );
    } catch (e) {
      console.error(e);
      this.formatMessage[format] = `Failed`;
      this.confirm_message = _(
        'project-open-data-section.confirm-message-format-message-failed'
      );
      setTimeout(() => {
        delete this.formatMessage[format];
        this.confirm_message = '';
      }, 10000);
    }
  }
}
