import { Component, OnInit } from '@angular/core';
import { News } from 'src/app/models/news.model';
import { NewsService } from 'src/app/services/news.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { OrderByFilter } from 'src/app/services/collection-generic-lib.service';
import { PageEvent } from '@angular/material/paginator';

interface NewsTitleDefinition {
  line1: string;
  line2: string;
}

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  isLoading = false;
  newsItems?: News[];
  newsTitleDefinition: NewsTitleDefinition = {
    line1: _('news-page.line1'),
    line2: _('news-page.line2'),
  };

  orderByTime: OrderByFilter = {
    date: 'desc',
  };

  currentPage: number = 1;
  pageSize: number = 5;
  totalItems: number;
  pagedNewsItems: News[];

  constructor(private newsService: NewsService) {}

  ngOnInit(): void {
    this.loadNews();
  }

  loadNews(): void {
    this.isLoading = true;
    this.newsService.listData({}, this.orderByTime).subscribe((news) => {
      this.newsItems = news;
      this.totalItems = news.length;
      this.updatePagedNewsItems();
      this.isLoading = false;
    });
  }

  updatePagedNewsItems(): void {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    this.pagedNewsItems = this.newsItems.slice(
      startIndex,
      startIndex + this.pageSize
    );
  }

  onPageChange(event: PageEvent): void {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.updatePagedNewsItems();
  }
}
