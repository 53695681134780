import { Pagination } from '../models/pagination.model';

export const COLLECTION_NAMES = {
    contributors: 'contributors',
    institutes: 'test20240806_Institutes',
    faculties: 'test20240523_Faculties',
    researchers: 'test20240828_Researchers',
    subThemes: 'test20240527_SubThemes',
    themes: 'test20240806_Themes',
    universities: 'test20240806_Universities',
    events: 'test20240818_Events',
    funding: 'test20240527_Funding',
    jobs: 'test20240820_Jobs',
    posts: 'test20240527_Posts',
    publications: 'test20241203_articles',
    journals: 'test20241203_journals',
    coAuthors: 'test20241203_coauthors',
    datasets: 'test20240813_Datasets',
    dataSetThemes: 'test20240813_ThemesDatasets',
    conferences: 'test20240818_Conferences',
    grants: "test20240818_Grants",
    users: "users"
};

export const accountActionConstants = {
    deleteAccount: 'DELETE_ACCOUNT',
    changeEmail: "CHANGE_EMAIl"
}

export const PAGE_CONSTANTS = {
    researchers: 'researchers',
    institutes: 'institutes',
    institute: 'institute',
    papers: 'papers',
    datasets: 'datasets',
    conferences: 'conferences',
    jobs: 'jobs',
    grants: 'grants',
    events: 'events'
};

export const FILTERS = {
    university: "UNIVERSITY",
    formats: "FORMATS",
    access: "ACCESS",
    country: 'COUNTRY',
    theme: "THEME",
    faculty: "FACULTY",
    journal: "JOURNAL",
    months: "MONTH",
    year: "YEAR",
    mode: "MODE",
    city: "CITY",
    company: "COMPANY",
    jobSector: "JOBSECTOR",
    experience: "EXPERIENCE",
    credentials: "CREDENTIALS",
    organizer: "ORGANIZER",
    deadLine: "DEADLINE",
    startDate: "STARTDATE",
    audience: "AUDIENCE",
    skills: "JOBSKILLS",
    role: "ROLE",
    journals: "JOURNAL"
};


export const logoStoragePath = {
    researchers: "profiler-images",
    resume: 'profiler-resume',
    posts: 'profiler-post-images',
    images: 'images'
};

export const COLORS = [
    '#A88AFF', '#23ABF9', '#FF528C', '#49E7A0', '#c7006e', '#3949ab', '#6c903c'
]

export const PAGINATION_MIN_PAGES = 5;
export const PAGINATION_MAX_PAGES = 10;
export const PAGINATION_PER_PAGE_DATA = 12;
export const FILTER_PER_PAGE_DATA = 100;
export const COUNTRIES_PER_PAGE = 10;
export const filterDateFormat = 'MMM d, y';
export const dateFormat = 'longDate';
export const shortDateFormat = 'M/yyyy';
export const splCharFormat = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
export const urlRegex = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
export const formValidationConstants = {
    email: "EMAIL",
    url: "URL",
    phone: "PHONE"
};

export const APP_URL = {
    emailAuthentication: 'email-verification'
}


export const INSTITUTE_ERR_IMG =
    'https://firebasestorage.googleapis.com/v0/b/susanhub-qa.appspot.com/o/institute-logos%2FinstituteSample.jpeg?alt=media&token=fbb023e7-3b63-4839-8252-3dc6296d05b6';

export const months = [
    {
        name: "January",
        id: "jan"
    },
    {
        name: "February",
        id: "feb"
    },
    {
        name: "March",
        id: "mar"
    },
    {
        name: "April",
        id: "apr"
    },
    {
        name: "May",
        id: "may"
    },
    {
        name: "June",
        id: "jun"
    },
    {
        name: "July",
        id: "jul"
    },
    {
        name: "August",
        id: "aug"
    },
    {
        name: "September",
        id: "sep"
    },
    {
        name: "October",
        id: "oct"
    },
    {
        name: "November",
        id: "nov"
    },
    {
        name: "December",
        id: "dec"
    }
];

export const DIRTY_WARNING_CONTENT = {
    title: "Warning: The changes are not saved.",
    body: "Are you sure you're discarding the changes?"
};

export const filterParameters = {
    countries: 'countries',
    universityIds: 'universityIds',
    formats: 'formats',
    access: 'access',
    month: 'month',
    year: 'year',
    company: "company",
    organizer: "organizer",
    mode: "mode",
    city: "city",
    jobSector: "jobSector",
    experience: "experience",
    credentials: "credentials",
    deadLines: "deadLine",
    startDate: "startDate",
    audience: "audience",
    recipient: "recipient",
    skills: "jobSkills",
    role: "universityTitle",
    journals: "journals"
};

export const dateRangeConstants = [
    'deadline', 'startdate'
];

export const filtersByPage = {
    institutes: [
        {
            title: "University",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.universityIds,
            showLoadMore: false,
            filterSearchConstant: FILTERS.university
        },
        {
            title: "Country",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.countries,
            showLoadMore: false,
            filterSearchConstant: FILTERS.country
        }
    ],
    datasets: [
        {
            title: "Format",
            showSearch: false,
            loading: false,
            data: [
                {
                    id: ['csv', 'xls', 'xlsx'],
                    name: 'CSV/XLS'
                },
                {
                    id: ['json'],
                    name: 'JSON'
                },
                {
                    id: ['txt'],
                    name: 'TXT'
                },
                {
                    id: ['ods'],
                    name: 'ODS'
                },
                {
                    id: ['png', 'jpg'],
                    name: 'PNG/JPG'
                },
                {
                    id: ['multiple formats'],
                    name: 'Multiple Formats'
                },
                {
                    id: ['tab', 'rds', 'do', 'mdb', 'sav', 'html', 'lpk', 'rdata', 'mp3'],
                    name: 'Others'
                },
            ],
            constant: filterParameters.formats,
            showLoadMore: false,
            filterSearchConstant: FILTERS.formats
        },
        {
            title: "Access",
            showSearch: false,
            loading: false,
            data: [
                {
                    name: 'Direct Download',
                    id: 'direct download'
                },
                {
                    name: 'Free Subscription',
                    id: 'free subscription'
                }
            ],
            constant: filterParameters.access,
            showLoadMore: false,
            filterSearchConstant: FILTERS.access
        },
    ],
    papers: [
        {
            title: "Journal",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.journals,
            showLoadMore: false,
            filterSearchConstant: FILTERS.journals
        },
        {
            title: "Status",
            showSearch: false,
            loading: false,
            data: [
                {
                    id: 'published',
                    name: 'Published'
                }
            ],
            constant: filterParameters.month,
            showLoadMore: false,
            filterSearchConstant: FILTERS.months
        },
        {
            title: "Year",
            showSearch: false,
            loading: false,
            data: getYears(true) || [],
            constant: filterParameters.year,
            showLoadMore: false,
            filterSearchConstant: FILTERS.year
        }
    ],
    conferences: [
        {
            title: "Country",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.countries,
            showLoadMore: false,
            filterSearchConstant: FILTERS.country
        },
        {
            title: "Month",
            showSearch: false,
            loading: false,
            data: months,
            constant: filterParameters.month,
            showLoadMore: false,
            filterSearchConstant: FILTERS.months
        },
        {
            title: "Year",
            showSearch: false,
            loading: false,
            data: getYears() || [],
            constant: filterParameters.year,
            showLoadMore: false,
            filterSearchConstant: FILTERS.year
        }
    ],
    events: [
        {
            title: "Date",
            showSearch: false,
            loading: false,
            data: [],
            constant: filterParameters.startDate,
            showLoadMore: false,
            filterSearchConstant: FILTERS.startDate
        },
        {
            title: "",
            isOpen: true,
            showSearch: false,
            loading: false,
            data: [
                { name: 'Virtual', id: 'virtual' },
                { name: 'In-person', id: 'in-person' }
            ],
            constant: filterParameters.mode,
            showLoadMore: false,
            filterSearchConstant: FILTERS.mode
        },
        {
            title: "Country",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.countries,
            showLoadMore: false,
            filterSearchConstant: FILTERS.country
        },
        {
            title: "City",
            showSearch: true,
            loading: false,
            data: [],
            dbConstant: COLLECTION_NAMES.events,
            constant: filterParameters.city,
            showLoadMore: true,
            filterSearchConstant: FILTERS.city
        },
        {
            title: "Organizer",
            showSearch: true,
            loading: false,
            data: [],
            dbConstant: COLLECTION_NAMES.events,
            constant: filterParameters.organizer,
            showLoadMore: true,
            filterSearchConstant: FILTERS.organizer
        }
    ],
    jobs: [

        {
            title: "Country",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.countries,
            showLoadMore: false,
            filterSearchConstant: FILTERS.country
        },
        {
            title: "City",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.city,
            dbConstant: COLLECTION_NAMES.jobs,
            showLoadMore: true,
            filterSearchConstant: FILTERS.city
        }, {
            title: "Type",
            showSearch: false,
            loading: false,
            data: [
                { name: 'Full time', id: 'full time' },
                { name: 'Part time', id: 'part time' },
                { name: 'Permanent', id: 'permanent' },
                { name: 'Temporary', id: 'temporary' },
                { name: 'Fixed Term Contract', id: 'fixed term contract' },
                { name: 'Volunteer', id: 'volunteer' },
                { name: 'Internship', id: 'internship' },
            ],
            constant: filterParameters.mode,
            showLoadMore: false,
            filterSearchConstant: FILTERS.mode
        },
        {
            title: "Organizer",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.company,
            dbConstant: COLLECTION_NAMES.jobs,
            showLoadMore: true,
            filterSearchConstant: FILTERS.company
        },
        {
            title: "Job sector",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.jobSector,
            dbConstant: COLLECTION_NAMES.jobs,
            showLoadMore: true,
            filterSearchConstant: FILTERS.jobSector
        },
        {
            title: "Experience",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.experience,
            dbConstant: COLLECTION_NAMES.jobs,
            showLoadMore: true,
            filterSearchConstant: FILTERS.experience
        },
        {
            title: "Credentials",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.credentials,
            dbConstant: COLLECTION_NAMES.jobs,
            showLoadMore: true,
            filterSearchConstant: FILTERS.credentials
        },
        {
            title: "Skills",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.skills,
            showLoadMore: true,
            filterSearchConstant: FILTERS.skills
        },
    ],
    grants: [

        {
            title: "Deadline",
            showSearch: false,
            loading: false,
            data: [],
            constant: filterParameters.deadLines,
            showLoadMore: false,
            filterSearchConstant: FILTERS.deadLine
        },
        {
            title: "Audience",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.audience,
            dbConstant: COLLECTION_NAMES.grants,
            showLoadMore: false,
            filterSearchConstant: FILTERS.audience
        }
    ],
    researchers: [
        {
            title: "Position",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.role,
            dbConstant: COLLECTION_NAMES.researchers,
            showLoadMore: false,
            filterSearchConstant: FILTERS.role
        },
        {
            title: "University",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.universityIds,
            showLoadMore: false,
            filterSearchConstant: FILTERS.university
        },
        {
            title: "Country",
            showSearch: true,
            loading: false,
            data: [],
            constant: filterParameters.countries,
            showLoadMore: false,
            filterSearchConstant: FILTERS.country
        }
    ],
};

export function removeNullAttributes(obj) {
    let newObj = {};
    Object.keys(obj).forEach((key) => {
        if (obj[key] === Object(obj[key]) && !Array.isArray(obj[key])) {
            newObj[key] = removeNullAttributes(obj[key]);
        } else if (
            obj[key] !== undefined &&
            obj[key] !== null) {
            newObj[key] = obj[key];
        }
    });

    return newObj;
};

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getYears(lastFiftyYears?) {
    const now = new Date().getFullYear();
    let pastYear = null;
    if (lastFiftyYears) {
        pastYear = now - 50;
    }
    const arr = [];
    for (let yr = (pastYear || now); yr < now + 5; yr++) {
        const obj = {
            name: yr.toString(),
            id: yr.toString()
        };
        arr.push(obj);
    }
    return arr || [];
}

export function getYearFilter() {
    const startYear = 1950;
    const endYear = getEndYear();
    const year = [];
    for (let i = startYear; i <= endYear; i++) {
        year.push({
            id: i,
            name: i
        });
    }

    return year.reverse();
}

export function getEndYear() {
    const now = new Date();
    now.setFullYear(now.getFullYear() + 6);
    return now.getFullYear();
}

export function getStartDate(startDate) {
    let sDate = startDate;
    if (sDate?.split(" ")?.length) {
        sDate = startDate.replace(" ", "T");
    }

    return sDate;
}

export function getDay(startDate, endDate) {
    let sDate = getStartDate(startDate);
    const d1 = new Date(sDate).toLocaleString('default', {
        day: '2-digit',
    });
    return d1.toString();
}

export function getYear(startDate, endDate) {
    let sDate = getStartDate(startDate);
    const d1 = new Date(sDate).getFullYear();
    return d1.toString();
}

export function getMonth(startDate, endDate) {
    let sDate = getStartDate(startDate);
    const d1 = new Date(sDate).toLocaleString('default', {
        month: 'short',
    });
    return d1.toString();
}

export function removeDuplicateData(array, attribute) {
    return [...
        array.reduce((map, obj) => {
            if (!map.has(obj[attribute])) map.set(obj[attribute], obj);
            return map;
        }, new Map)
            .values()
    ];
}