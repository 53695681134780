import { FirebaseDeserializable } from './FirebaseDeserializable';
import { Model } from './generic-model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { InternationalizedService } from '../services/internationalized.service';

export class ContributorsDTO extends Model {
  //What Firebase should return from the contributors document
  public picture?: string;
  public firstName?: string;
  public lastName?: string;
  public titles?: { en: string; fr: string; es: string };
  public biography?: { en: string; fr: string; es: string };
  public quote?: { en: string; fr: string; es: string };
  public projectIds?: string[];
  public linkedIn?: string;
  public board?: boolean;
  public studentPartner?: boolean;
  public isFacultyMember?: boolean;
  public dmTitle?: { en: string; fr: string; es: string };
  public isAdvisor?: boolean;
}

export class Contributors
  extends ContributorsDTO
  implements FirebaseDeserializable<ContributorsDTO>
{
  deserialize(input: ContributorsDTO): this {
    Object.assign(this, input);
    return this;
  }

  get displayTitle() {
    const lang = InternationalizedService.getLocale();
    return this.titles[lang].split('\\n');
  }

  get displayTitleModal() {
    const lang = InternationalizedService.getLocale();
    return this.titles[lang].split('\\n').join(', ');
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get displayBiography() {
    const lang = InternationalizedService.getLocale();
    return this.biography[lang];
  }

  get displayQuote() {
    const lang = InternationalizedService.getLocale();
    return this.quote[lang];
  }

  get displayDmTitle() {
    const lang = InternationalizedService.getLocale();
    return this.dmTitle[lang];
  }
}
