import { Component } from '@angular/core';
import { OpenDataToken } from 'src/app/models/open-data-token.model';
import { OpenDatas } from 'src/app/models/open-datas.model';
import { OpenDataTokenService } from 'src/app/services/open-data-token.service';
import { OpenDatasService } from 'src/app/services/open-datas.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent {
  isLoading = true;
  dataTableRequest: OpenDataToken[];
  dataTableCurrent: OpenDatas[];

  constructor(
    private openDataTokenService: OpenDataTokenService,
    private openDatasService: OpenDatasService
  ) {
    this.openDataTokenService.listData().subscribe((tokens) => {
      this.dataTableRequest = tokens;
    });
    this.openDatasService.listData().subscribe((datas) => {
      this.dataTableCurrent = datas;
    });
    this.isLoading = false;
  }
}
