<header [class.dark-theme]="true" class="new-layout">
  <section class="header header-box">
    <!-- mobile view -->
    <div class=" header-mobile">
      <div class="header-mobile__logo">
        <a routerLink="/"><img src="/assets/images/susan-logo.svg" width="135" height="56" alt="susan-logo" /></a>
      </div>
      <div class="header-mobile__menu flex gap-x-3">
        <a routerLink="/auth" *ngIf="!(userAuth$ | async)">
          <button mat-flat-button color="primary">
            <strong>LOG IN</strong>
          </button>
        </a>
        <button *ngIf="(userAuth$ | async)" class="!w-16 !h-16" mat-icon-button [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu">
          <img class="!w-full !h-full rounded-full object-cover"
            [src]="profile?.logo ? profile?.logo : '../../../../assets/images/person.png'" alt="">
        </button>
        <div (click)="this.onClickSideMenu($event)" class="cursor-pointer">
          <img src="/assets/images/menu-line.svg" alt="menu" />
        </div>
      </div>
    </div>

    <!-- Desktop view -->
    <div class="2xl:container 2xl:mx-auto header-desktop">
      <div class="header-desktop__logo">
        <a routerLink="/"><img src="/assets/images/susan-logo.svg" width="135" height="56" alt="susan-logo" /></a>
      </div>
      <div class="header-desktop__menu">
        <a routerLink="/datasets" class="header-desktop__menu__menu-item" [ngClass]="
            this.currentPath === 'datasets'
              ? 'header-desktop__menu__item-selected'
              : ''
          ">
          <div style="
              mask: url(/assets/images/top-nav/datasets.svg) no-repeat center;
            " class="header-desktop__menu__menu-item__image" [ngClass]="
              this.currentPath === 'datasets'
                ? 'header-desktop__menu__image-selected'
                : ''
            "></div>
          <div>Datasets</div>
        </a>
        <a routerLink="/research-papers" class="header-desktop__menu__menu-item" [ngClass]="
            this.currentPath === 'research-papers'
              ? 'header-desktop__menu__item-selected'
              : ''
          ">
          <div style="
              mask: url(/assets/images/top-nav/papers.svg) no-repeat center;
            " class="header-desktop__menu__menu-item__image" [ngClass]="
              this.currentPath === 'research-papers'
                ? 'header-desktop__menu__image-selected'
                : ''
            "></div>
          <div>Papers</div>
        </a>
        <a [routerLink]="'/researchers'" class="header-desktop__menu__menu-item" [ngClass]="
            this.currentPath === 'researchers'
              ? 'header-desktop__menu__item-selected'
              : ''
          ">
          <div style="
              mask: url(/assets/images/top-nav/researchers.svg) no-repeat center;
            " class="header-desktop__menu__menu-item__image" [ngClass]="
              this.currentPath === 'researchers'
                ? 'header-desktop__menu__image-selected'
                : ''
            "></div>
          <div>Researchers</div>
        </a>
        <a routerLink="/institutes">
          <div class="header-desktop__menu__menu-item" [ngClass]="
              this.currentPath === 'institutes'
                ? 'header-desktop__menu__item-selected'
                : ''
            ">
            <div style="
                mask: url(/assets/images/top-nav/institutes.svg) no-repeat
                  center;
              " class="header-desktop__menu__menu-item__image" [ngClass]="
                this.currentPath === 'institutes'
                  ? 'header-desktop__menu__image-selected'
                  : ''
              "></div>
            <div>Institutes</div>
          </div>
        </a>
        <a routerLink="/conferences" class="header-desktop__menu__menu-item" [ngClass]="
            this.currentPath === 'conferences'
              ? 'header-desktop__menu__item-selected'
              : ''
          ">
          <div style="
              mask: url(/assets/images/top-nav/conferences.svg) no-repeat center;
            " class="header-desktop__menu__menu-item__image" [ngClass]="
              this.currentPath === 'conferences'
                ? 'header-desktop__menu__image-selected'
                : ''
            "></div>
          <div>Conferences</div>
        </a>

        <a routerLink="/jobs" class="header-desktop__menu__menu-item" [ngClass]="
            this.currentPath === 'jobs'
              ? 'header-desktop__menu__item-selected'
              : ''
          ">
          <div style="
              mask: url(/assets/images/top-nav/jobs.svg) no-repeat center;
            " class="header-desktop__menu__menu-item__image" [ngClass]="
              this.currentPath === 'jobs'
                ? 'header-desktop__menu__image-selected'
                : ''
            "></div>
          <div>Jobs</div>
        </a>


        <a routerLink="/events" class="header-desktop__menu__menu-item" [ngClass]="
            this.currentPath === 'events'
              ? 'header-desktop__menu__item-selected'
              : ''
          ">
          <div style="
              mask: url(/assets/images/top-nav/events.svg) no-repeat center;
            " class="header-desktop__menu__menu-item__image" [ngClass]="
              this.currentPath === 'events'
                ? 'header-desktop__menu__image-selected'
                : ''
            "></div>
          <div>Events</div>
        </a>


        <a routerLink="/grants" class="header-desktop__menu__menu-item" [ngClass]="
            this.currentPath === 'grants'
              ? 'header-desktop__menu__item-selected'
              : ''
          ">
          <div style="
              mask: url(/assets/images/top-nav/grants.svg) no-repeat center;
            " class="header-desktop__menu__menu-item__image" [ngClass]="
              this.currentPath === 'grants'
                ? 'header-desktop__menu__image-selected'
                : ''
            "></div>
          <div>Grants</div>
        </a>

      </div>

      <div class="flex gap-x-2">
        <button *ngIf="(userAuth$ | async)" class="!w-16 !h-16" mat-icon-button [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu">
          <img class="!w-full !h-full rounded-full object-cover"
            [src]="profile?.logo ? profile?.logo : '../../../../assets/images/person.png'" alt="">
        </button>
        <mat-menu #menu="matMenu" class="w-[230px] rounded-xl menu-bottom">
          <div class="flex gap-x-2 ">
            <img class="!w-10 !h-10 rounded-full"
              [src]="profile?.logo ? profile?.logo : '../../../../assets/images/person.png'" alt="">
            <div class="flex flex-col">
              <span class="text-sm !font-medium text-[#24292E] normal-case">{{(user$ | async)?.firstName}}</span>
              <span class="text-xs !font-medium text-[#6A737D]">{{(user$ | async)?.email}}</span>
            </div>
          </div>
          <button class="!mt-2" mat-menu-item routerLink="/profile">
            <span class="text-sm !font-medium px-2 text-[#24292E]">My profile</span>
          </button>
          <button mat-menu-item routerLink="/settings">
            <span class="text-sm !font-medium px-2 text-[#24292E]">Account settings</span>
          </button>
          <button routerLink="/logout" mat-menu-item>
            <span class="text-sm !font-medium px-2 text-[#24292E]">Logout</span>
          </button>

        </mat-menu>


        <button *ngIf="!(userAuth$ | async)" mat-flat-button color="primary" class="text-sm font-semibold"
          [routerLink]="['/auth']">LOG
          IN</button>

        <button *ngIf="!(userAuth$ | async)" mat-flat-button color="primary" class="text-sm font-semibold"
          [routerLink]="['/onboarding']">SIGN
          UP</button>

      </div>
    </div>

    <!-- See more menu -->
    <div id="see-more" class="see-more" style="display: none">

      <a routerLink="/jobs" class="see-more__content">
        <div style="mask: url(/assets/images/top-nav/jobs.svg) no-repeat" class="see-more__content__image" [ngClass]="
            this.currentPath === 'jobs'
              ? 'see-more__content__image-selected'
              : ''
          "></div>
        <div [ngClass]="
            this.currentPath === 'jobs'
              ? 'see-more__content__item-selected'
              : ''
          ">
          Jobs
        </div>
      </a>
      <a routerLink="/events" class="see-more__content">
        <div style="mask: url(/assets/images/top-nav/events.svg) no-repeat" class="see-more__content__image" [ngClass]="
            this.currentPath === 'events'
              ? 'see-more__content__image-selected'
              : ''
          "></div>
        <div [ngClass]="
            this.currentPath === 'events'
              ? 'see-more__content__item-selected'
              : ''
          ">
          Events
        </div>
      </a>

      <a routerLink="/grants" class="see-more__content">
        <div style="mask: url(/assets/images/top-nav/grants.svg) no-repeat" class="see-more__content__image" [ngClass]="
            this.currentPath === 'grants'
              ? 'see-more__content__image-selected'
              : ''
          "></div>
        <div [ngClass]="
            this.currentPath === 'grants'
              ? 'see-more__content__item-selected'
              : ''
          ">
          Grants
        </div>
      </a>
    </div>

    <!-- Side menu -->
    <div id="side-menu" class="side-menu" style="display: none">

      <div class="side-menu__menu">
        <div class="flex justify-end align-center">

          <span (click)="this.onClickSideMenu($event)" class="cursor-pointer">
            <img src="../../../../assets/images/close_icon.svg" alt="">
          </span>
        </div>

        <a routerLink="/datasets" (click)="this.onClickSideMenu($event)" class="side-menu__menu__content"
          style="margin-top: 24px">
          <div class="side-menu__menu__content__image" [ngClass]="
              this.currentPath === 'datasets'
                ? 'side-menu__menu__content__image-selected'
                : ''
            " style="mask: url(/assets/images/top-nav/datasets.svg) no-repeat"></div>
          <div [ngClass]="
              this.currentPath === 'datasets'
                ? 'side-menu__menu__item-selected'
                : ''
            ">
            Datasets
          </div>
        </a>
        <a [routerLink]="'/research-papers'" (click)="this.onClickSideMenu($event)" class="side-menu__menu__content">
          <div class="side-menu__menu__content__image" [ngClass]="
              this.currentPath === 'research-papers'
                ? 'side-menu__menu__content__image-selected'
                : ''
            " style="mask: url(/assets/images/top-nav/papers.svg) no-repeat"></div>
          <div [ngClass]="
              this.currentPath === 'research-papers'
                ? 'side-menu__menu__content__item-selected'
                : ''
            ">
            Papers
          </div>
        </a>
        <a [routerLink]="'/researchers'" (click)="this.onClickSideMenu($event)" class="side-menu__menu__content">
          <div class="side-menu__menu__content__image" [ngClass]="
              this.currentPath === 'researchers'
                ? 'side-menu__menu__content__image-selected'
                : ''
            " style="mask: url(/assets/images/top-nav/researchers.svg) no-repeat"></div>
          <div [ngClass]="
              this.currentPath === 'researchers'
                ? 'side-menu__menu__content__item-selected'
                : ''
            ">
            Researchers
          </div>
        </a>
        <a routerLink="/institutes" (click)="this.onClickSideMenu($event)">
          <div class="side-menu__menu__content">
            <div class="side-menu__menu__content__image" [ngClass]="
                this.currentPath === 'institutes'
                  ? 'side-menu__menu__content__image-selected'
                  : ''
              " style="mask: url(/assets/images/top-nav/institutes.svg) no-repeat"></div>
            <div [ngClass]="
                this.currentPath === 'institutes'
                  ? 'side-menu__menu__content__item-selected'
                  : ''
              ">
              Institutes
            </div>
          </div>
        </a>
        <a class="side-menu__menu__content" routerLink="/conferences" (click)="this.onClickSideMenu($event)">
          <div class="side-menu__menu__content__image" [ngClass]="
              this.currentPath === 'conferences'
                ? 'side-menu__menu__content__image-selected'
                : ''
            " style="mask: url(/assets/images/top-nav/conferences.svg) no-repeat"></div>
          <div [ngClass]="
              this.currentPath === 'conferences'
                ? 'side-menu__menu__content__item-selected'
                : ''
            ">
            Conferences
          </div>
        </a>
        <!-- <div class="side-menu__menu__content">
          <div
            class="side-menu__menu__content__image"
            [ngClass]="false ? 'side-menu__menu__content__image-selected' : ''"
            style="mask: url(/assets/images/top-nav/universities.svg) no-repeat"
          ></div>
          <div
            [ngClass]="false ? 'side-menu__menu__content__item-selected' : ''"
          >
            Universities
          </div>
        </div> -->
        <a routerLink="/jobs" (click)="this.onClickSideMenu($event)" class="side-menu__menu__content">
          <div class="side-menu__menu__content__image" [ngClass]="
              this.currentPath === 'jobs'
                ? 'side-menu__menu__content__image-selected'
                : ''
            " style="mask: url(/assets/images/top-nav/jobs.svg) no-repeat"></div>
          <div [ngClass]="
              this.currentPath === 'jobs'
                ? 'side-menu__menu__content__item-selected'
                : ''
            ">
            Jobs
          </div>
        </a>
        <a routerLink="/events" (click)="this.onClickSideMenu($event)" class="side-menu__menu__content">
          <div class="side-menu__menu__content__image" [ngClass]="
              this.currentPath === 'events'
                ? 'side-menu__menu__content__image-selected'
                : ''
            " style="mask: url(/assets/images/top-nav/events.svg) no-repeat"></div>
          <div [ngClass]="
              this.currentPath === 'events'
                ? 'side-menu__menu__content__item-selected'
                : ''
            ">
            Events
          </div>
        </a>
        <!-- <div class="side-menu__menu__content">
          <div
            class="side-menu__menu__content__image"
            [ngClass]="false ? 'side-menu__menu__content__image-selected' : ''"
            style="mask: url(/assets/images/top-nav/themes.svg) no-repeat"
          ></div>
          <div
            [ngClass]="false ? 'side-menu__menu__content__item-selected' : ''"
          >
            Themes
          </div>
        </div> -->
        <a routerLink="/grants" class="side-menu__menu__content" (click)="this.onClickSideMenu($event)">
          <div class="side-menu__menu__content__image" [ngClass]="
              this.currentPath === 'grants'
                ? 'side-menu__menu__content__image-selected'
                : ''
            " style="mask: url(/assets/images/top-nav/grants.svg) no-repeat"></div>
          <div [ngClass]="
              this.currentPath === 'grants'
                ? 'side-menu__menu__content__item-selected'
                : ''
            ">
            Grants
          </div>
        </a>
      </div>
    </div>
  </section>
</header>