<div [class.dark-theme]="true" class="fixed inset-0 flex items-end justify-end w-full z-50 px-4" *ngIf="!close">
  <div class="bg-white rounded-lg shadow-xl p-6 w-full border">
    <div class="flex justify-between items-start relative">
      <h2 class="text-xl font-semibold text-gray-900">Accept the use of cookies.</h2>
      <button (click)="closeDialog()"
        class="text-gray-500 hover:text-gray-700 focus:outline-none text-2xl absolute right-0" aria-label="Close">
        &times;
      </button>
    </div>
    <p class="text-gray-700 mt-4">
      We use cookies to improve your browsing experience, serve personalized
      content, and analyze our traffic. By clicking Accept all Cookies, you
      agree to the storing of cookies on your device.
    </p>
    <p class="text-gray-700">
      You can customize your settings by clicking Manage Preferences. For more
      details, see our
      <a href="#" class="text-indigo-600 hover:underline">Cookie Policy</a>.
    </p>
    <div class="my-6 flex space-x-4 justify-end">
      <button mat-flat-button color="primary" class="w-max" (click)="acceptCookies()">
        Accept all Cookies
      </button>
      <button mat-flat-button color="" style="border: 1px solid !important;" class="w-max" (click)="closeDialog()">
        Deny
      </button>
    </div>
  </div>
</div>