import { Injectable } from '@angular/core';
import { CollectionGenericLibService } from './collection-generic-lib.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subject } from 'rxjs';
import { User, UserDTO } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService extends CollectionGenericLibService<User, UserDTO> {
  COLLECTION_NAME = 'users';
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public override firestore: AngularFirestore) {
    super(firestore);
    this.c = User;
    this.collectionName = this.COLLECTION_NAME;
  }

  destroySubscriptions() {
    this.destroyGenericSubscriptions();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
