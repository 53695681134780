import { Component, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { OpenDatas } from 'src/app/models/open-datas.model';
import { OpenDatasService } from 'src/app/services/open-datas.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin-current-access',
  templateUrl: './admin-current-access.component.html',
  styleUrls: ['./admin-current-access.component.scss'],
})
export class AdminCurrentAccessComponent {
  @Input() title: string;
  private _data: OpenDatas[];
  isLoading = false;

  columns = [
    {
      key: 'displayCreatedAt',
      header: 'Since',
    },
    { key: 'displayName', header: 'User' },
    { key: 'displayEmail', header: 'Email' },
    { key: 'displayTitleAdmin', header: 'Project' },
    {
      key: 'status',
      header: 'Status',
    },
    { key: 'lastColumnKey', header: '', id: 'id' },
  ];

  dataSource = new MatTableDataSource<OpenDatas>(this.data);

  @Input()
  set data(value: OpenDatas[]) {
    this._data = value;
    this.dataSource.data = value;
  }

  get data(): OpenDatas[] {
    return this._data;
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private openDatasService: OpenDatasService,
    private snackBar: MatSnackBar
  ) {}

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getDisplayedColumns(): string[] {
    return this.columns.map((column) => column.key);
  }

  async onRemove(data: OpenDatas) {
    this.isLoading = true;
    try {
      await this.openDatasService.deleteOpenData(data.id);
      this.snackBar.open('Success', 'OK');
    } catch (e) {
      console.error(e);
      this.snackBar.open(
        'An Error has occured, please try again or contact support',
        'OK'
      );
    }
    this.isLoading = false;
  }
}
