import { Component, EventEmitter, Output, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { passwordValidator } from '../../shared/helpers/passwordValidator';
import { SessionMode } from '../session.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  @Output() switchMode: EventEmitter<SessionMode> =
    new EventEmitter<SessionMode>();

  @Input() oobCode: string = null;
  mode: 'loading' | 'success' | 'failed' = 'success';

  resetPasswordForm: FormGroup;
  waitForApi = false;
  firebaseAuthErrorCode: string;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {
    this.initLoginForm();
  }

  initLoginForm() {
    this.resetPasswordForm = this.fb.group(
      {
        password: [null, Validators.required],
        passwordConfirmation: [null, [Validators.required]],
      },

      {
        validators: passwordValidator('password', 'passwordConfirmation'),
      }
    );
  }

  submit() {
    if (this.resetPasswordForm.invalid) {
      return;
    }
    this.waitForApi = true;
    const password = this.resetPasswordForm.value.password;

    this.auth
      .resetPassword(this.oobCode, password)
      .then(() => {
        this.router.navigate(['session/confirmation']).then();
      })
      .catch((e) => {
        console.error(e);
        this.snackBar.open(this.auth.generateAuthErrorMessage(e), 'OK');
        this.waitForApi = false;
      });
  }
}
