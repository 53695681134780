import { Injectable } from '@angular/core';
import { CollectionGenericLibService } from './collection-generic-lib.service';
import { News, NewsDTO } from '../models/news.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class NewsService extends CollectionGenericLibService<News, NewsDTO> {
  COLLECTION_NAME = 'news';

  constructor(public override firestore: AngularFirestore) {
    super(firestore);
    this.c = News;
    this.collectionName = this.COLLECTION_NAME;
  }
}
