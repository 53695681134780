<form class="forget-password" [formGroup]="forgotPassword_form">
  <div
    class="forget-password__email-sent"
    *ngIf="isEmailSent; else resetPassword"
  >
    <p
      class="forget-password__email-sent__description"
      [innerHTML]="'forget-password.email-sent.text' | translate"
    ></p>
    <a (click)="switchMode.emit('login')">{{
      "forget-password.signin.title" | translate
    }}</a>
  </div>
  <ng-template #resetPassword>
    <div class="forget-password__field">
      <mat-form-field class="forget-password__field__item" appearance="outline">
        <mat-label>{{ "forget-password.email-label" | translate }}</mat-label>
        <mat-icon matPrefix>email</mat-icon>
        <input matInput [formControlName]="'email'" />
        <mat-error></mat-error>
      </mat-form-field>
      <p class="forget-password__field__error-message" *ngIf="errorMessage">
        {{ "forget-password.errorMessage" | translate }}
      </p>
    </div>

    <div class="forget-password__action">
      <a (click)="switchMode.emit('login')">{{
        "forget-password.signin.title" | translate
      }}</a>

      <button-loading
        color="primary"
        [loading]="wait_for_api"
        [disabled]="forgotPassword_form.invalid"
        (click)="submit()"
      >
        {{ "forget-password.button-forget-password" | translate }}
      </button-loading>
    </div>
  </ng-template>
</form>
