import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-new-footer',
  templateUrl: './new-footer.component.html',
  styleUrls: ['./new-footer.component.scss'],
})
export class NewFooterComponent implements OnInit {
  newsletterForm: FormGroup;
  isLoading = false;
  isValid = true;
  isSuccess = false;
  isSomethingWrong = false;

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
    private fsFunction: AngularFireFunctions
  ) { }

  ngOnInit(): void {
    this.newsletterForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  async subscribeNewsletter() {
    const emailValue = this.newsletterForm.value.email;
    this.isValid = this.newsletterForm.valid;

    if (this.isValid) {
      this.isLoading = true;
      const resAddEmailToList = await this.addEmailToList(emailValue);

      if (resAddEmailToList) {
        this.newsletterForm.reset();
        this.isLoading = false;
        this.isSuccess = true;
      } else {
        this.isLoading = false;
        this.isSomethingWrong = true;
      }
    }
  }

  addEmailToList(email) {
    return firstValueFrom(
      this.fsFunction.httpsCallable('subscribeToNewsletter')({
        email,
        language: this.translateService.currentLang,
      })
    );
  }
}
