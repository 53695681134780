import { Component, OnInit } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { mapMarker, mapStyle } from './mapStyle';
import { Institute } from '../../models/institute.model';
import { InstituteService } from 'src/app/services/institute.service';
import { Router } from '@angular/router';

interface SustainabilityTitleDefinition {
  line1: string;
  line2: string;
}

interface InstituteFilter {
  country?: string;
  universities?: string;
  categories?: string;
  subcategories?: string;
}

@Component({
  selector: 'app-sustainability-directory',
  templateUrl: './sustainability-directory.component.html',
  styleUrls: ['./sustainability-directory.component.scss'],
})
export class SustainabilityDirectoryComponent implements OnInit {
  sustainbilityTitleDefinition: SustainabilityTitleDefinition = {
    line1: _('sustainability-directory-page.line1'),
    line2: _('sustainability-directory-page.line2'),
  };

  isLoading = true;
  institutes?: Institute[];
  filteredInstitutes?: Institute[];

  countries: string[] = [];
  universities: string[] = [];
  categories: string[] = [];
  subcategories: string[] = [];

  lat = 40.73061;
  lng = -73.935242;
  zoom = 2;
  styles = mapStyle;
  iconUrl = mapMarker;

  // Initialize search terms
  searchTerms: InstituteFilter = {
    country: '',
    universities: '',
    categories: '',
    subcategories: '',
  };

  constructor(
    private instituteListService: InstituteService,
    private router: Router
  ) {
    this.instituteListService.listData().subscribe((institutes) => {
      this.institutes = institutes;
      this.filteredInstitutes = institutes;

      this.loadSearchFilters();

      this.isLoading = false;
    });
  }

  ngOnInit(): void {}

  loadSearchFilters() {
    this.countries = [
      ...new Set(this.institutes?.map((institute) => institute.country)),
    ];

    this.universities = [
      ...new Set(
        this.institutes?.flatMap((institute) => institute.universities)
      ),
    ];

    this.categories = [
      ...new Set(this.institutes?.flatMap((institute) => institute.categories)),
    ];

    this.subcategories = [
      ...new Set(
        this.institutes?.flatMap((institute) => institute.subcategories)
      ),
    ];
  }

  redirectToInstitute(institute: Institute) {
    if (institute.uuid) {
      this.router.navigate(['/institute/' + institute.uuid]);
    }
  }

  onSearch() {
    if (!this.searchTerms || this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.filteredInstitutes = this.institutes?.filter((institute) => {
      return Object.entries(this.searchTerms).every(
        ([key, value]) =>
          !value ||
          (Array.isArray(institute[key])
            ? institute[key].includes(value)
            : institute[key] === value)
      );
    });

    this.isLoading = false;
  }
}
