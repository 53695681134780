import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Contributors } from 'src/app/models/contributors.model';

@Component({
  selector: 'app-contributor-card',
  templateUrl: './contributor-card.component.html',
  styleUrls: ['./contributor-card.component.scss'],
})
export class ContributorCardComponent {
  @Input() contributor: Contributors;
  @Output() exit: EventEmitter<void> = new EventEmitter<void>();
}
