<div class="top-nav">
  <a class="top-nav__brand" [routerLink]="'/'">
    {{ "top-nav.title" | translate }}
  </a>
  <div class="top-nav__content">
    <a
      [routerLink]="'/institutes'"
      class="top-nav__content__item top-nav__content__item--link"
    >
      {{ "top-nav.sustainability-directory" | translate }}
    </a>
    <a
      [routerLink]="'/'"
      class="top-nav__content__item top-nav__content__item--link"
      >{{ "top-nav.project" | translate }}</a
    >
    <a
      *ngIf="showTeamPage"
      [routerLink]="'/team'"
      class="top-nav__content__item top-nav__content__item--link"
      >{{ "top-nav.team" | translate }}</a
    >
    <a
      [routerLink]="'/news'"
      class="top-nav__content__item top-nav__content__item--link"
      >{{ "top-nav.news" | translate }}</a
    >

    <a
      [routerLink]="'/about'"
      class="top-nav__content__item top-nav__content__item--link"
      >{{ "top-nav.about" | translate }}</a
    >
    <!-- Burger menu -->
    <button
      mat-button
      [matMenuTriggerFor]="language"
      class="top-nav__container-burger"
    >
      <div class="top-nav__container-burger__mobile">
        <img
          class="top-nav__container-burger__mobile__burger"
          src="../../../../assets/images/burger-menu.png"
          alt="menu"
        />
      </div>

      <mat-menu
        #language="matMenu"
        class="top-nav__container-burger__burger-content"
      >
        <a
          class="top-nav__container-burger__burger-content__link"
          [routerLink]="'/institutes'"
        >
          {{ "top-nav.sustainability-directory" | translate }}
        </a>
        <a
          class="top-nav__container-burger__burger-content__link"
          [routerLink]="'/'"
          >{{ "top-nav.project" | translate }}</a
        >
        <a
          class="top-nav__container-burger__burger-content__link"
          [routerLink]="'/team'"
          >{{ "top-nav.team" | translate }}</a
        >
        <a
          class="top-nav__container-burger__burger-content__link"
          [routerLink]="'/news'"
          >{{ "top-nav.news" | translate }}</a
        >

        <a
          class="top-nav__container-burger__burger-content__link"
          [routerLink]="'/about'"
        >
          {{ "top-nav.about" | translate }}</a
        >
      </mat-menu>
    </button>
    <div class="top-nav__container-burger__content__lang">
      <button mat-button [matMenuTriggerFor]="menu">
        <span class="top-nav__content__item">{{ langageText }}</span>
      </button>
      <mat-menu #menu="matMenu">
        <button
          class="top-nav__content__menu-item"
          (click)="toggleLanguage('en')"
          mat-menu-item
        >
          English
        </button>
        <button
          class="top-nav__content__menu-item"
          (click)="toggleLanguage('fr')"
          mat-menu-item
        >
          Français
        </button>
        <button
          class="top-nav__content__menu-item"
          (click)="toggleLanguage('es')"
          mat-menu-item
        >
          Español
        </button>
      </mat-menu>
    </div>
    <ng-container *ngIf="current_profile; else connection">
      <a class="top-nav__content__item">
        {{ current_profile.fullName }}
      </a>
      <a
        *ngIf="isAdmin"
        class="top-nav__content__button top-nav__content__button--admin"
        [routerLink]="'/admin'"
      >
        <img src="assets/images/icon-admin.svg" alt="Admin Icon" />
      </a>
      <a
        matTooltip="({{ 'top-nav.tooltip' | translate }})"
        class="top-nav__content__button top-nav__content__button--logout"
        (click)="logout()"
      >
        <img src="assets/images/logout.svg" alt="Logout Icon" />
      </a>
    </ng-container>
    <ng-template #connection>
      <a
        [routerLink]="'/session'"
        *ngIf="current_profile === null"
        class="top-nav__content__item"
      >
        {{ "top-nav.signin" | translate }}
      </a>
    </ng-template>
  </div>
</div>
