import { InternationalizedService } from '../services/internationalized.service';
import { FirebaseDeserializable } from './FirebaseDeserializable';
import { Model } from './generic-model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface Screenshot {
  picture: string;
  description?: string;
}

export class ProjectDTO extends Model {
  //What Firebase should return from the projects document
  public legal_text?: { en: string; es: string; fr: string };
  public legal_text_title: { en: string; es: string; fr: string };
  public description?: { en: string; es: string; fr: string };
  public historic?: { en: string; es: string; fr: string };
  public icon?: string;
  public mission?: { en: string; es: string; fr: string };
  public open_data_export_url?: string;
  public screenshots?: {
    description: { [key: string]: string };
    picture: string;
  }[];
  public instituteIds?: string[];
  public title?: { en: string; es: string; fr: string };
  public type?: string;
  public uuid?: string;
  public website?: string;
}

export class Project
  extends ProjectDTO
  implements FirebaseDeserializable<ProjectDTO>
{
  deserialize(input: ProjectDTO): this {
    Object.assign(this, input);
    return this;
  }

  get displayLegalText() {
    return this.legal_text[InternationalizedService.getLocale()];
  }
  get displayLegalTextTite() {
    return this.legal_text_title[InternationalizedService.getLocale()];
  }
  get displayDescription() {
    return this.description[InternationalizedService.getLocale()];
  }
  get displayHistoric() {
    return this.historic[InternationalizedService.getLocale()];
  }
  get displayMission() {
    return this.mission[InternationalizedService.getLocale()];
  }
  get displayTitle() {
    return this.title[InternationalizedService.getLocale()];
  }
}
