<div class="dots" [ngClass]="{ 'dots--reverse': reverse }">
  <div
    class="dots__line dots__line--1"
    [ngStyle]="{ background: getBackgroundEffect() }"
  ></div>
  <div
    class="dots__line dots__line--2"
    [ngStyle]="{ background: getBackgroundEffect() }"
  ></div>
</div>
