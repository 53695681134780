import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Institute } from '../../models/institute.model';
import { InstituteService } from 'src/app/services/institute.service';
import { Project } from 'src/app/models/project-list.model';
import { ProjectsListService } from 'src/app/services/projects-list.service';
import { ResearchersService } from 'src/app/services/researchers.service';
import { Researchers, level } from 'src/app/models/researcher.model';

@Component({
  selector: 'app-institute-page',
  templateUrl: './institute-page.component.html',
  styleUrls: ['./institute-page.component.scss'],
})
export class InstitutePageComponent {
  isLoading = true;
  institute: Institute;
  instituteProjects: Project[];
  instituteResearchers: Researchers[];
  researcherLevels: level[] = ['Associate Director', 'Executive Committee'];

  selectedResearchers: Researchers;

  constructor(
    private activatedRoute: ActivatedRoute,
    private instituteListService: InstituteService,
    private researcherService: ResearchersService,
    private projectService: ProjectsListService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.isLoading = true;

      this.instituteListService
        .listData({
          uuid: {
            comparator: '==',
            value: params['id'],
          },
        })
        .subscribe(async (institutes) => {
          this.institute = institutes[0];

          this.researcherService
            .listData({
              instituteIds: {
                comparator: 'array-contains',
                value: this.institute.uuid,
              },
            })
            .subscribe((researchers) => {
              this.instituteResearchers = researchers;
            });

          this.projectService
            .listData({
              instituteIds: {
                comparator: 'array-contains',
                value: this.institute.uuid,
              },
            })
            .subscribe((projects) => {
              this.instituteProjects = projects;
            });

          this.isLoading = false;
        });
    });
  }

  selectResearcher(researcher: Researchers) {
    this.selectedResearchers = researcher;
  }

  filterResearchers(level: string) {
    return this.instituteResearchers?.filter(
      (researcher) => researcher.level === level
    );
  }

  selectProject(project: Project) {
    const url = `https://datamangrove.com/project/${project.uuid}`;
    window.open(url, '_blank');
  }
}
