import { Model } from './generic-model';
import { FirebaseDeserializable } from './FirebaseDeserializable';
import { InternationalizedService } from '../services/internationalized.service';
import { Timestamp } from 'firebase/firestore';
import { User } from './user.model';
import { Project } from './project-list.model';

export class OpenDatasDTO extends Model {
  public created_at: any;
  public project_data: Project;
  public user_data: User;
  public project_id: string;
  public user_id: string;
  public status: string;
}

export class OpenDatas
  extends OpenDatasDTO
  implements FirebaseDeserializable<OpenDatasDTO>
{
  deserialize(input: OpenDatas): this {
    Object.assign(this, input);
    return this;
  }

  constructor(input: any = {}) {
    super(input);
    if (input.hasOwnProperty('project_data')) {
      this.project_data = new Project(input.project_data);
    }
    if (input.hasOwnProperty('user_data')) {
      this.user_data = new User(input.user_data);
    }
    return this.deserialize(input);
  }

  get displayName() {
    return this.user_data.full_name;
  }

  get displayEmail() {
    return this.user_data.email;
  }

  get displayTitleAdmin() {
    return this.project_data.title.en;
  }

  get displayCreatedAt() {
    const date = new Timestamp(
      this.created_at.seconds,
      this.created_at.nanoseconds
    );
    return date.toDate().toDateString();
  }
}
