import { InternationalizedService } from '../services/internationalized.service';
import { FirebaseDeserializable } from './FirebaseDeserializable';
import { Model } from './generic-model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export class SponsorsDTO extends Model {
  //What Firebase should return from the sponsors document
  public name?: { en: string; es: string; fr: string };
  public picture?: string;
  public projectIds: string[];
  public website: string;
}

export class Sponsors
  extends SponsorsDTO
  implements FirebaseDeserializable<SponsorsDTO>
{
  constructor(data: any = {}) {
    super();
    for (const name in data) {
      if (data.hasOwnProperty(name)) {
        this[name] = data[name];
      }
    }
  }

  deserialize(input: SponsorsDTO): this {
    Object.assign(this, input);
    return this;
  }

  get displayName() {
    return this.name[InternationalizedService.getLocale()];
  }
}
