import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionMode } from '../session.component';
import { AuthenticationService } from '../../../services/authentication.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firstValueFrom } from 'rxjs';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @Output() switchMode: EventEmitter<SessionMode> =
    new EventEmitter<SessionMode>();

  login_form: FormGroup;
  wait_for_api = false;

  reactivate: 'hidden' | 'visible' | 'pending' | 'loading' | 'sent' = 'hidden';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackbar: MatSnackBar,
    private auth: AuthenticationService
  ) {
    this.initLoginForm();
  }

  ngOnInit() {}

  initLoginForm() {
    this.login_form = this.fb.group({
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  async resendActivationEmail() {
    try {
      this.reactivate = 'loading';
      localStorage.setItem(
        `last-reactivation-attempt-${this.login_form.value.email}`,
        new Date().toUTCString()
      );
      await this.auth.resendEmailVerification(this.login_form.value.email);
      this.reactivate = 'sent';
    } catch (e) {
      this.reactivate = 'pending';
      console.error(e);
    }
  }

  async submit() {
    if (this.login_form.invalid) {
      return;
    }
    this.wait_for_api = true;

    try {
      await this.auth.signInWithEmailAndPassword(
        this.login_form.value.email,
        this.login_form.value.password
      );
      this.wait_for_api = false;
      await this.router.navigate(['/']);
      this.snackbar.open('Sign in successful', 'OK', {
        duration: 5000,
      });
    } catch (e) {
      if (e.code === 'auth/email-not-verified') {
        this.initReactivateEmail();
      }

      console.error(e);
      this.wait_for_api = false;
      this.snackbar.open(this.auth.generateAuthErrorMessage(e), 'OK', {
        duration: 5000,
      });
    }
  }

  initReactivateEmail() {
    const last_attempt = localStorage.getItem(
      `last-reactivation-attempt-${this.login_form.value.email}`
    );

    if (!last_attempt) {
      this.reactivate = 'visible';
      return;
    }

    const MIN_MINUTES = 30;
    const current_date = DateTime.now();
    const minute_passed = current_date.diff(
      DateTime.fromJSDate(new Date(last_attempt)),
      ['minutes']
    ).minutes;

    if (minute_passed >= MIN_MINUTES) {
      this.reactivate = 'visible';
    } else {
      this.reactivate = 'pending';
    }
  }
}
