<div class="news">
  <div class="news__hero">
    <div class="news__hero__background">
      <div class="news__hero__background__layer"></div>
      <div class="news__hero__background__fade-layer"></div>
      <video
        class="news__hero__background__video"
        [autoplay]="true"
        [muted]="true"
        [loop]="true"
        [playbackRate]="0.7"
      >
        <source src="../../../assets/videos/homepage.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="news__hero__title">
      <img
        class="news__hero__title__image"
        src="../../../../assets/images/shape_title_about_us.svg"
        alt="Hero Title"
      />
      <div class="news__hero__title__text">
        {{ "news-page.title" | translate }}
      </div>
    </div>

    <div class="news__hero__subtitle">
      {{ "news-page.subtitle" | translate }}
    </div>
    <app-dots class="news__hero__dots"></app-dots>
  </div>

  <app-full-width-title
    [definition]="newsTitleDefinition"
    class="news__title"
  ></app-full-width-title>
  <div class="news__loading-screen" *ngIf="isLoading; else loadedContent">
    <mat-spinner> </mat-spinner>
  </div>
  <ng-template #loadedContent>
    <div class="news__page">
      <div class="news__page__item" *ngFor="let newsItem of pagedNewsItems">
        <app-news-card [newsData]="newsItem"></app-news-card>
      </div>
    </div>
  </ng-template>

  <div class="news__pagination">
    <mat-paginator
      [length]="totalItems"
      [pageSizeOptions]="[5, 10, 50]"
      [pageSize]="pageSize"
      (page)="onPageChange($event)"
    >
    </mat-paginator>
  </div>
</div>
