<form class="login" [formGroup]="login_form">
  <div class="login__field">
    <mat-form-field class="login__field__item" appearance="outline">
      <mat-label>{{ "login.email-label" | translate }}</mat-label>
      <mat-icon matPrefix>email</mat-icon>
      <input matInput [formControlName]="'email'" />
      <mat-error></mat-error>
    </mat-form-field>
  </div>

  <div class="login__field">
    <mat-form-field class="login__field__item" appearance="outline">
      <mat-label>{{ "login.password-label" | translate }}</mat-label>
      <mat-icon matPrefix>key</mat-icon>
      <input matInput [type]="'password'" [formControlName]="'password'" />
      <mat-error></mat-error>
    </mat-form-field>
  </div>

  <div class="login__action">
    <div class="login__action__link">
      <a (click)="switchMode.emit('register')">{{
        "login.register.title" | translate
      }}</a>

      <a (click)="switchMode.emit('forgetPassword')">{{
        "login.forget-password.link" | translate
      }}</a>
    </div>

    <button-loading color="primary" [loading]="wait_for_api" (click)="submit()">
      {{ "login.button-signin" | translate }}
    </button-loading>
  </div>

  <span class="login__reverify">
    <ng-container *ngIf="reactivate === 'pending'">
      {{ "login.pending-text" | translate }}
    </ng-container>

    <ng-container *ngIf="reactivate === 'visible'">
      {{ "login.visible-text" | translate }}
      <a (click)="resendActivationEmail()">{{
        "login.button-resend-activation-email" | translate
      }}</a>
    </ng-container>
    <mat-spinner diameter="15" *ngIf="reactivate === 'loading'"> </mat-spinner>
    <ng-container *ngIf="reactivate === 'sent'">
      {{ "login.email-sent-text" | translate }}
    </ng-container>
  </span>
</form>
