import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-sh-theme-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sh-theme-item.component.html',
  styleUrls: ['./sh-theme-item.component.scss']
})
export class ShThemeItemComponent {
  @Input() themeData: any;
  @Input() imageShow: boolean;
  @Input() classesData : boolean;
}
