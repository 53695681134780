<div class="error-forbidden">
  <div class="error-forbidden__hero">
    <div class="error-forbidden__hero__background">
      <div class="error-forbidden__hero__background__layer"></div>
      <div class="error-forbidden__hero__background__fade-layer"></div>
      <video
        class="error-forbidden__hero__background__video"
        [autoplay]="true"
        [muted]="true"
        [loop]="true"
        [playbackRate]="0.7"
      >
        <source src="../../../assets/videos/homepage.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="error-forbidden__hero__title">
      <img
        class="error-forbidden__hero__title__image"
        src="../../../assets/images/warning.svg"
      />
      <div class="error-forbidden__hero__title__text">403</div>
    </div>

    <div class="error-forbidden__hero__subtitle">
      {{ "error-forbidden.message" | translate }}
    </div>
  </div>
</div>
